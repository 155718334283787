import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

import useMobileDetect from 'use-mobile-detect-hook';
import { useSelector } from 'react-redux';

const Header = (props) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  // Черная/белая плашка под Хедером
  const [blackBackground, setBlackBackground] = useState(true);
  const location = useLocation();
  const exhibitions = useSelector((state) => state.exhibitions);

  const [orderLink, setOrderLink] = useState();
  const [orderDict, setOrderDict] = useState();

  useEffect(() => {
    const isActiveExhibition = exhibitions.exhibitions.find(
      (item) => item.id === +exhibitions.activeExhibitionIndex
    );
    if (
      (location.pathname.includes('gallery') &&
        !isActiveExhibition?.is_background_black) ||
      location.pathname.includes('all_exhibitions') ||
      location.pathname.includes('painters') ||
      location.pathname.includes('archive')
      // || location.pathname.includes('cluster')
    ) {
      setBlackBackground(false);
    } else {
      setBlackBackground(true);
    }

    if (location.pathname.includes('gallery')) {
      setOrderLink('/gallery/order/');
      setOrderDict(props.galleryOrder);
    } else if (location.pathname.includes('showroom')) {
      setOrderLink('/showroom/order/');
      setOrderDict(props.showroomOrder);
    } else {
      setOrderLink('');
      setOrderDict([]);
    }
  }, [location, props.galleryOrder, props.showroomOrder, exhibitions]);

  // Убираются лишние паддинги при скролле
  const [scroll, setScroll] = useState(false);
  const root = document.getElementById('root');
  function handleScroll() {
    let scrollDistance = root.scrollTop;
    if (scrollDistance > 50) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  }
  useEffect(() => {
    root.addEventListener('scroll', handleScroll);
    // удаляется слушатель событий чтобы не засирать оперативную память (иначе создается куча слушателей при перелистывании страниц)
    return () => {
      root.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      {isMobile ? (
        <MobileHeader
          background={blackBackground}
          scroll={scroll}
          order={!!orderLink}
          orderLink={orderLink}
          orderDict={orderDict}
          // {...props}
        />
      ) : (
        <DesktopHeader
          background={blackBackground}
          scroll={scroll}
          order={!!orderLink}
          orderLink={orderLink}
          orderDict={orderDict}
          // {...props}
        />
      )}
    </>
  );
};

export default Header;
