import React from 'react';
import cn from 'classnames';
import styles from './IconButton.module.scss';

export default function IconButton({
  intent = 'primary',
  size = 'md',
  classname,
  ...props
}) {
  const classes = cn(
    [styles.icon],
    { [styles.primary]: intent == 'primary' },
    { [styles.secondary]: intent == 'secondary' },
    { [styles.sm]: size == 'sm' },
    { [styles.md]: size == 'md' },
    { [styles.lg]: size == 'lg' },
    classname
  );
  return <button className={classes} {...props} />;
}
