import classNames from 'classnames';
import { ReactComponent as Point } from '../../../../../assets/img/cluster/point.svg';
import { ReactComponent as VK } from '../../../../../assets/img/vk.svg';
import { ReactComponent as TG } from '../../../../../assets/img/tg.svg';
import address from '../../../../../assets/img/cluster/address.png';
import address2 from '../../../../../assets/img/cluster/address2.jpg';
import address3 from '../../../../../assets/img/cluster/address3.jpg';
import styles from './ClusterFooter.module.scss';

const ClusterFooter = () => {
  return (
    <section className={styles.clusterFooter}>
      <div className={styles.header}>
        <Point />
        <p>Как нас найти?</p>
      </div>
      <div className={styles.addressWrapper2}>
        <div className={styles.addressWrapper}>
          <p className={styles.address}>адрес:</p>
          <p>
            г. Красноярск, ул. Красной Армии, 10 к.4, помещение 302, 660017
          </p>
          <p className={styles.contact}>связь с нами:</p>
          <div className={styles.links}>
            <a className={styles.link} href='tel:+73912191969'>
              + 7 (391) 219-19-69
            </a>
            <a className={styles.link} href='mailto:nfo@industry.art'>
              info@industry.art
            </a>
          </div>
          <p className={styles.smallText}>
            industry.art — наше креативное пространство
          </p>
        </div>
        <div className={styles.images}>
          <img src={address} />
          <img src={address2} />
          <img src={address3} />
        </div>
      </div>
      <div className={styles.footer}>
        <p>индустрия 2023</p>
        <div>
          <a target='_blank' href='https://vk.com/place_industry'>
            <VK />
          </a>
          <a target='_blank' href='https://t.me/industry_art'>
            <TG />
          </a>
        </div>
      </div>
    </section>
  );
};
export default ClusterFooter;
