/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
// import { Link } from "react-router-dom";
import styles from "./ActiveCard.module.scss";

import { useNavigate } from "react-router-dom";

import useMobileDetect from "use-mobile-detect-hook";

const ActiveCard = ({ card, closeActiveCard }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  let navigate = useNavigate();
  return (
    <>
      <div className={styles.cross} onClick={closeActiveCard}></div>
      <div
        onPointerUp={() => {
          navigate(`/showroom/categories/${card.id}`);
        }}
        to={`/showroom/categories/${card.id}`}
        className={styles.cardTitle}
      >
        <div className={styles.card}>
          <div className={styles.cardText}>
            <div className={styles.cardTitle}>{card.title}</div>
            {card && (
              <div className={styles.cardDescription}>{card.description}</div>
            )}

            <a
              href="#"
              className={
                isMobile
                  ? `${styles.cardButton} ${styles.cardButton__mobile}`
                  : styles.cardButton
              }
            >
              <span>Подробнее</span>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.overlay}></div>
    </>
  );
};

export default ActiveCard;
