import styles from './VolumeInput.module.scss';

export default function VolumeInput({ volume, onVolumeChange }) {
  return (
    <input
      aria-label='volume'
      name='volume'
      type='range'
      min={0}
      step={0.05}
      max={1}
      value={volume}
      className={styles.volume}
      onChange={(e) => {
        onVolumeChange(e.currentTarget.valueAsNumber);
      }}
    />
  );
}
