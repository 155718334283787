import { BASE_URL_V2 } from '../API/BASE_URL';

export const couponUsage = async (token, userId, couponId) => {
  const response = await fetch(BASE_URL_V2 + 'partners/coupons-usage/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: token ? 'Token ' + token : '',
    },
    body: JSON.stringify({ coupon: +couponId, user: +userId }),
  });

  if (!response.ok) {
    const data = await response.json();
    const res = data.detail ? data.detail : data.non_field_errors.join(' ');
    return res;
  } else {
    return 'Успешно!';
  }
};
