import React from 'react';
import styles from '../MainPage.module.scss';
import Container from '../../../../templates/Container';
import { songs } from '../../../../../assets/songs/songs';

import { ReactComponent as ArrowRight } from '../../../../../assets/img/arrowRightSlider.svg';
import { ReactComponent as ArrowLeft } from '../../../../../assets/img/arrowLeftSlider.svg';
import AudioPlayer from '../../../../elements/Audio/AudioPlayer/AudioPlayer';
import { useState } from 'react';
import { useRef } from 'react';

const MobileMainPageContent = ({
  data,
  sliderContent,
  activeExhibition,
  setActiveExhibition,
  exhibitionsCount,
  marginTop,
  next,
  prev,
}) => {
  const { id, image, title, sub_title, description } = data;
  const audioPlayerRef = useRef();

  const [currentSongIndex, setCurrentSongIndex] = useState(0);

  const currentSong = songs[currentSongIndex];

  const colors = ['#D6338A', '#2AA89A', '#4F4C93', '#F0E400'];
  const getRandomInteger = () => {
    return Math.floor(Math.random() * 4);
  };

  const formateDate = (date) => {
    const array = date.split('-');

    const monthes = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];

    return array[2] + ' ' + monthes[+array[1] - 1] + ' ' + array[0];
  };

  return (
    <div
      className={styles.mobile_main_page_container}
      style={marginTop ? { marginTop: '10px' } : { marginTop: '' }}
    >
      <Container>
        <div
          className={styles.mobile_main_image_wrapper}
          style={{ backgroundColor: colors[getRandomInteger()] }}
        >
          <ArrowLeft className={styles.prev} onClick={() => prev(id)} />
          <img src={image} alt={title} />
          <ArrowRight className={styles.next} onClick={() => next(id)} />
          <div className={styles.info}>
            <h1>{title}</h1>
            <div>
              {data.start_date && <span>{formateDate(data.start_date)} </span>}
              {data.finish_date && (
                <span>
                  {'  -  '}
                  {formateDate(data.finish_date)}
                </span>
              )}
            </div>
          </div>
        </div>
        {description && <p className={styles.description}>{description}</p>}
        {sub_title && (
          <p className={styles.sub_title}>
            {data.id == 11
              ? `${sub_title.split(';')[0]} ${sub_title.split(';')[1]}`
              : sub_title}
          </p>
        )}
        {data.id == 11 && (
          <div>
            <AudioPlayer
              audioPlayerRef={audioPlayerRef}
              key={currentSongIndex}
              currentSong={currentSong}
              songCount={songs.length}
              songIndex={currentSongIndex}
              onNext={() => setCurrentSongIndex((i) => i + 1)}
              onPrev={() => setCurrentSongIndex((i) => i - 1)}
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default MobileMainPageContent;
