import HTMLReactParser from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import RecommendationPlace from './RecommendationPlace/RecommendationPlace';
import styles from './PathInfo.module.scss';
import { ReactComponent as Point } from '../../../../../assets/img/cluster/point.svg';

import useMobileDetect from 'use-mobile-detect-hook';
import classNames from 'classnames';
import { getOrgs } from '../../../../../redux/requests/getOrgs';

const PathInfo = ({ path, cluster = false }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();
  const [open, setOpen] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const textRef = useRef();

  let [time, setTime] = useState('00:00');
  useEffect(() => {
    getOrgs().then((data) => setOrgs(data?.results));
  }, []);

  useEffect(() => {
    cluster ? setTime(getPathTimeCluster(path)) : setTime(getPathTime(path));
  }, [path]);

  return (
    <div
      className={
        isMobile
          ? `${styles.path} ${styles.path__mobile}`
          : !cluster
          ? styles.path
          : styles.clusterWrapper
      }
    >
      {!cluster ? (
        <div
          className={
            isMobile ? `${styles.info} ${styles.info__mobile}` : styles.info
          }
        >
          <div
            className={
              isMobile ? `${styles.time} ${styles.time__mobile}` : styles.time
            }
          >
            {time}
          </div>
          <div
            className={
              isMobile
                ? `${styles.event_title} ${styles.event_title__mobile}`
                : styles.event_title
            }
          >
            {path.event_title}
          </div>
          {path.event_location_start == null &&
          path.event_location_end == null ? null : (
            <div
              className={
                isMobile
                  ? `${styles.location} ${styles.location__mobile}`
                  : styles.location
              }
            >
              {path.event_location_start}{' '}
              {path.event_location_end == null ? null : '|'}{' '}
              {path.event_location_end}
            </div>
          )}
          <div
            className={
              isMobile ? `${styles.desc} ${styles.desc__mobile}` : styles.desc
            }
          >
            {path.description && HTMLReactParser(path.description)}
          </div>
        </div>
      ) : (
        <>
          <div
            ref={textRef}
            className={classNames(styles.cluster, {
              [styles.clusterOpen]: open,
            })}
          >
            {!open ? (
              <>
                <p className={styles.day}>
                  {getDayOfMonthFromDate(path.datetime)}
                </p>
                <div className={styles.timeWrapper}>
                  <p className={styles.month}>
                    {getMonthInGenitiveCaseFromDate(path.datetime)}
                  </p>
                  <p className={styles.dayofWeek}>
                    {getDayOfWeekFromDate(path.datetime)}
                  </p>
                  <p className={styles.start}>начало</p>
                  <p className={styles.time}>{time}</p>
                </div>
                <div className={styles.descWrapper}>
                  <p className={styles.title}>{path.title}</p>
                  <div className={styles.place}>
                    <Point />
                    {path.place}
                  </div>
                  <button
                    onClick={() => {
                      setOpen(true);
                      textRef.current.scrollIntoView({
                        behavior: 'smooth',
                      });
                    }}
                    className={styles.button}
                  >
                    Подробнее
                  </button>
                </div>
                {path?.places?.length > 0 && (
                  <img className={styles.image} src={path?.cover} />
                )}
              </>
            ) : (
              <div className={styles.openWrapper}>
                <div className={styles.aboutEvent}>
                  <p className={styles.titleBig}>{path.title}</p>
                  <div className={styles.placeBig}>
                    <Point />
                    {path.place}
                  </div>
                  <div className={styles.buttons}>
                    <button
                      onClick={() => setOpen(false)}
                      className={styles.button}
                    >
                      Свернуть
                    </button>
                    {path.registration_link && (
                      <a
                        target='_blank'
                        href={path.registration_link}
                        className={styles.button}
                      >
                        Зарегистрироваться
                      </a>
                    )}
                    {path.ticket_sales_link && (
                      <a
                        target='_blank'
                        href={path.ticket_sales_link}
                        className={styles.buttonPink}
                      >
                        Билеты
                      </a>
                    )}
                  </div>
                </div>
                <div className={styles.timeWrapper2}>
                  <div className={styles.timee}>
                    <p className={styles.day2}>
                      {getDayOfMonthFromDate(path.datetime)}
                    </p>
                    <div>
                      <p className={styles.month2}>
                        {getMonthInGenitiveCaseFromDate(path.datetime)}
                      </p>
                      <p className={styles.dayofWeek2}>
                        {getDayOfWeekFromDate(path.datetime)}
                      </p>
                    </div>
                  </div>
                  <p className={styles.start2}>Время проведения</p>
                  <p className={styles.time2}>{time}</p>
                </div>
              </div>
            )}
          </div>
          {open && (
            <div className={styles.openEvent}>
              <div className={styles.left}>
                <p className={styles.eventTitle}>/ Подробнее о мероприятии</p>
                <p
                  className={styles.eventText}
                  dangerouslySetInnerHTML={{
                    __html: path.description,
                  }}
                ></p>
                <p className={styles.eventTitle}>/ Ключевые треки программы</p>
                <p
                  className={styles.eventText}
                  dangerouslySetInnerHTML={{
                    __html: path.event_programm,
                  }}
                ></p>
              </div>
              <div className={styles.right}>
                <p className={styles.eventDesc}>Место проведения</p>
                <p className={styles.eventPlace}>{path.place}</p>
                <p className={styles.eventDesc}>Организаторы</p>
                {path?.organizers?.map((org, id) => (
                  <div className={styles.org} key={id}>
                    <img
                      src={orgs.find((orgPic) => orgPic.title == org).cover}
                    />
                    <p>{org}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {/* Блок рекомендаций */}
      {!cluster &&
        (isMobile ? (
          // Мобилка
          <div
            className={`${styles.recomend} ${styles.recomend__mobile}`}
            data-recomend='Рекомендуем посетить'
            style={
              path.places?.length > 0
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <div
              className={`${styles.recomend_text} ${styles.recomend_text__mobile}`}
            >
              {path.recommendation}
            </div>
            <div className={`${styles.grid} ${styles.grid__mobile}`}>
              {path.places &&
                path.places.map((place) => (
                  <RecommendationPlace key={place.id} place={place} />
                ))}
            </div>
          </div>
        ) : (
          // Десктоп
          <div
            style={
              path.places?.length > 0
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <div
              className={styles.recomend}
              data-recomend='Рекомендуем посетить'
            >
              <div className={styles.grid}>
                {path.places &&
                  path.places.map((place) => (
                    <RecommendationPlace key={place.id} place={place} />
                  ))}
              </div>
            </div>
            <div className={styles.recomend_text}>{path.recommendation}</div>
          </div>
        ))}
    </div>
  );
};

export default PathInfo;

function getPathTime(path) {
  let date = new Date(path.start_datetime);
  if (path.start_datetime) {
    return `${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`;
  }
}
function getPathTimeCluster(path) {
  let date = new Date(path.datetime);
  if (path.datetime) {
    return `${('0' + date.getHours()).slice(-2)}:${(
      '0' + date.getMinutes()
    ).slice(-2)}`;
  }
}
function getDayOfWeekFromDate(dateString) {
  const date = new Date(dateString);

  const dayOfWeekNumber = date.getDay();

  const daysOfWeek = [
    'воскресенье',
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
  ];

  const dayOfWeek = daysOfWeek[dayOfWeekNumber];

  return dayOfWeek;
}

function getMonthInGenitiveCaseFromDate(dateString) {
  const date = new Date(dateString);

  const monthNumber = date.getMonth();

  const monthsInGenitiveCase = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  const monthInGenitiveCase = monthsInGenitiveCase[monthNumber];

  return monthInGenitiveCase;
}
function getDayOfMonthFromDate(dateString) {
  const date = new Date(dateString);

  const dayOfMonth = date.getDate();

  return dayOfMonth ? dayOfMonth : '';
}
