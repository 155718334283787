import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from '../Logo/Logo';
// import useMobileDetect from "use-mobile-detect-hook";
import Burger from '../Burger/Burger';
import styles from './MobileHeader.module.scss';
import MobileHeaderButtonGroup from '../MobileHeaderButtonGroup/MobileHeaderButtonGroup';
import { useSelector } from 'react-redux';

const MobileHeader = (props) => {
  const location = useLocation();

  // Проверка наличия архивных выставок для отображения пункта меню "Архив выставок"
  const dataGallery = useSelector((state) => state.exhibitions.exhibitions);
  const [containDataGallery, setContainDataGallery] = useState(0);
  useEffect(() => {
    setContainDataGallery(dataGallery.find((item) => item.is_archived));
  }, [dataGallery]);

  const [modalActive, setModalActive] = useState(false);
  useEffect(() => {
    !modalActive
      ? (document.body.style.overflow = 'auto')
      : (document.body.style.overflow = 'hidden');
  }, [modalActive]);

  // плавный скролл до партнеров и до верха страницы
  const linkLogo = useRef();
  const scrollToTop = function () {
    const root = document.getElementById('root');
    root.scrollTo(0, 0);
  };
  useEffect(() => {
    linkLogo.current?.addEventListener('click', scrollToTop);
    return () => {
      linkLogo.current?.removeEventListener('scroll', scrollToTop);
    };
  }, [location.pathname]);

  return (
    <div
      className={
        styles.header +
        ' ' +
        (props.background ? styles.header__black : styles.header__white) +
        ' ' +
        (props.scroll ? styles.header__scroll : '')
      }
    >
      <div className={styles.header_content}>
        <NavLink
          to='/'
          className={({ isActive }) => (isActive ? styles.pressed + ' ' + styles.logo : styles.logo)}
          ref={linkLogo}
        >
          <Logo />
        </NavLink>

        <div
          className={
            styles.navLinks +
            ' ' +
            (props.background ? styles.navLinks__black : styles.navLinks__white)
          }
        >
          <NavLink
            to='/cluster#cluster'
            className={({ isActive }) => (isActive ? styles.pressed + ' ' + styles.navItem : styles.navItem)}
          >
            О&nbsp;Кластере
          </NavLink>

          <NavLink
            to='/gallery'
            className={({ isActive }) => (isActive ? styles.pressed + ' ' + styles.navItem : styles.navItem)}
          >
            Галерея
          </NavLink>

          <NavLink
            to='/showroom'
            className={({ isActive }) => (isActive ? styles.pressed + ' ' + styles.navItem : styles.navItem)}
          >
            Шоурум
          </NavLink>
        </div>

        <MobileHeaderButtonGroup
          header={props.header}
          order={props.order}
          orderLink={props.orderLink}
          orderDict={props.orderDict}
        />

        <Burger
          modalActive={modalActive}
          setModalActive={setModalActive}
          order={props.order}
        />
      </div>

      <div
        className={
          modalActive
            ? props.header
              ? `${styles.panel} ${styles.dark}`
              : styles.panel
            : props.header
            ? `${styles.panel} ${styles.panel__hide} ${styles.dark}`
            : `${styles.panel} ${styles.panel__hide}`
        }
      >
        <div
          className={styles.overlay}
          onClick={() =>
            modalActive ? setModalActive(false) : setModalActive(true)
          }
        ></div>

        {/* Контентная часть */}
        <div
          className={
            styles.panel_content +
            ' ' +
            (modalActive ? '' : styles.panel_content__hide) +
            ' ' +
            (props.background
              ? styles.panel_content__black
              : styles.panel_content__white)
          }
          onClick={() =>
            modalActive ? setModalActive(false) : setModalActive(true)
          }
        >
          {/* <NavLink
            to='/cluster#cluster'
            className={({ isActive }) => (isActive ? styles.pressed : '')}
          >
            О Кластере
          </NavLink> */}
          <NavLink
            to='/#partners'
            className={({ isActive }) => (isActive ? styles.pressed : '')}
          >
            Партнеры
          </NavLink>
          <NavLink
            to='/painters'
            className={({ isActive }) => (isActive ? styles.pressed : '')}
          >
            Художники
          </NavLink>

          {containDataGallery && (
            <NavLink
              to='/archive'
              className={({ isActive }) => (isActive ? styles.pressed : '')}
            >
              Архив выставок
            </NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
