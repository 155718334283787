import styles from './Class.module.scss';
import { ReactComponent as LISVG } from '../../../../../../assets/img/cluster/rezidents/programms/liPurple.svg';

const Class = () => {
  return (
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <p className={styles.title}>по виду деятельности</p>
        <ul className={styles.list}>
          <li>
            <LISVG /> Целевые
          </li>
          <ul className={styles.innerList}>
            <li>постоянные</li>
            <li>постоянные почасовые</li>
          </ul>
          <li>
            <LISVG />
            Cервисные
          </li>
        </ul>
        <p className={styles.title}>
          по сроку ведения предпринимательской деятельности
        </p>
        <ul className={styles.list2}>
          <li>
            <LISVG />
            Начинающие предприниматели
          </li>
          <li>
            <LISVG />
            Действующие предприниматели
          </li>
        </ul>
        <div className={styles.stavka}>
          <p className={styles.stavkaText}>
            арендная
            <br /> СТАВКА
          </p>
          <p>=632 руб. /кв.м</p>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.cards}>
          <div className={styles.card}>
            <p className={styles.cardTitle}>начинающие</p>
            <p className={styles.cardPercent}>
              О-3 МЕС. - 0<br /> 3-6 МЕС. - 15%
              <br /> 6-9 МЕС. - 26% <br /> 9-12 МЕС. - 35%
            </p>
            <p className={styles.cardText}>
              вновь зарегистрированные и действующие менее 2 (двух) лет
            </p>
            <p className={styles.cardText2}>
              проекты в первичной стадии развития и имеют потенциал для быстрого
              роста
            </p>
          </div>
          <div className={styles.card}>
            <p className={styles.cardTitle2}>действующие</p>
            <p className={styles.cardPercent}>
              0-12 МЕС. - 50%
              <br /> 13-24 МЕС. - 75%
              <br /> 25-36 МЕС. - 100%
            </p>
            <p className={styles.cardText}>
              проекты в стадии активного роста и развития
            </p>
            <p className={styles.cardText2}>
              бизнес, утвердившийся на рынке и приносящий стабильный доход
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Class;
