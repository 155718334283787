import styles from './ClusterPromo.module.scss';

import React, { useEffect, useState, useRef } from 'react';
import { getPaths } from '../../../../../redux/requests/getPaths';
import Container from '../../../../templates/Container';

import useMobileDetect from 'use-mobile-detect-hook';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PathDays, {
  getDaysFromClusterArray,
  getDaysFromPathsArray,
} from '../../../events/PromoPaths/PathDays/PathDays';
import PathMonths, {
  getMonthsFromClusterArray,
  getMonthsFromPathsArray,
} from '../../../events/PromoPaths/PathMonths/PathMonths';
import {
  getClusterToDisplay,
  getPathToDisplay,
} from '../../../events/PromoPaths/PromoPaths';
import PathsInfo from '../../../events/PathsInfo/PathsInfo';
import { getEventsCluster } from '../../../../../redux/requests/getEventsCluster';
import { getOrgs } from '../../../../../redux/requests/getOrgs';

const ClusterPromo = () => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();
  const mode = 'out-in';
  const cardRef = useRef(null);

  let info = useRef();

  let [pathsData, setPathsData] = useState([]);
  let [activeMonth, _setActiveMonth] = useState('');
  let [paths, setPaths] = useState([]);
  let [activeDay, _setActiveDay] = useState(0);

  useEffect(() => {
    getEventsCluster().then((res) => {
      let pathsData = res.results;
      setPathsData(pathsData);
      let months = getMonthsFromClusterArray(pathsData);
      setActiveMonth(months[0]);
      let days = getDaysFromClusterArray(pathsData, months[0]);
      setActiveDay(days[0]);
      setPaths(getClusterToDisplay(pathsData, months[0], days[0]));
    });

  }, []);

  function setActiveMonth(month) {
    _setActiveMonth(month);
    let days = getDaysFromClusterArray(pathsData, month);
    setPaths(getClusterToDisplay(pathsData, month, days[0]));
  }

  function setActiveDay(day) {
    setPaths(getClusterToDisplay(pathsData, activeMonth, day));
    _setActiveDay(day);
  }
  return (
    <section id='events' className={styles.clusterPromo}>
      <p className={styles.title}>Календарь мероприятий</p>

      <div
        className={
          isMobile ? `${styles.grid} ${styles.grid__mobile}` : styles.grid
        }
      >
        <PathMonths
          cluster
          pathsData={pathsData}
          setActiveMonth={setActiveMonth}
        />
        <PathDays
          cluster
          pathsData={pathsData}
          setActiveDay={setActiveDay}
          activeMonth={activeMonth}
          activeDay={activeDay}
        />
        <SwitchTransition mode={mode}>
          <CSSTransition
            className={'fade'}
            // key={paths[0]?.id}
            key={activeDay}
            nodeRef={cardRef}
            timeout={{ enter: 250, exit: 250 }}
            unmountOnExit
          >
            <PathsInfo paths={paths} customRef={cardRef} cluster />
          </CSSTransition>
        </SwitchTransition>
      </div>
    </section>
  );
};
export default ClusterPromo;
