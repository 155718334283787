import classNames from 'classnames';

import styles from './TableCeil.module.scss';
import { formatDateToYYYYMMDD } from '../../ClusterArenda';

const TableCeil = ({
  index,
  time,
  add,
  bookingDays,
  weekDays,
  addBookingDays,
  last = false,
}) => {
  return (
    <td
      onClick={() => {
        if (
          weekDays[index].getTime() +
            parseInt(time) * 1000 * 60 * 60 -
            Date.now() >
          0
        )
          add(time, index);
      }}
      className={classNames(
        styles.tableColor,
        {
          [styles.tableBlockColor]:
            bookingDays[index]?.hours.booking.find((hour) => hour == time) ||
            weekDays[index].getTime() +
              parseInt(time) * 1000 * 60 * 60 -
              Date.now() <
              0,
        },
        { [styles.last]: last },
        {
          [styles.tablePickColor]: addBookingDays
            .find((item) => item.date == formatDateToYYYYMMDD(weekDays[index]))
            ?.hours.find((hour) => hour == time),
        }
      )}
    ></td>
  );
};

export default TableCeil;
