import styles from './Events.module.scss';
import { ReactComponent as LISVG } from '../../../../../../assets/img/cluster/rezidents/programms/li.svg';
import { useEffect, useState } from 'react';
import { getEventsSlider } from '../../../../../../redux/requests/getEventsSlider';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSlider.scss';

const Events = () => {
  const [sliderImages, setSliderImages] = useState([]);
  const settings = {
    dotsClass: 'dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    getEventsSlider().then((data) => setSliderImages(data));
  }, []);
  return (
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <div className={styles.image}>
          <Slider {...settings}>
            {sliderImages.map((image, id) => (
              <div key={id}>
                <img className={styles.slider} src={image.cover} />
              </div>
            ))}
          </Slider>
        </div>
        <div className={styles.lists}>
          <ul className={styles.list}>
            <li>
              <LISVG />
              формирование корпоративной культуры
            </li>
            <li>
              <LISVG />
              кооперация участников кластера
            </li>
            <li>
              <LISVG />
              выявление инициатив
            </li>
            <li>
              <LISVG />
              стимулирование новых форм сотрудничества
            </li>
          </ul>
          <ul className={styles.list}>
            <li>
              <LISVG />
              создание и реализация кластерных проектов
            </li>
            <li>
              <LISVG />
              мониторинг и оценка
            </li>
            <li>
              <LISVG />
              современный подход к решению задач развития инфраструктуры
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.countPurple}>
          <p className={styles.numberPurple}>1.</p>
          <p>Проектные сессии </p>
        </div>
        <div className={styles.count}>
          <p className={styles.number}>2.</p>
          <p>Рабочие собрания резидентов</p>
        </div>
        <div className={styles.count}>
          <p className={styles.number}>3.</p>
          <p>Заседание совета кластера</p>
        </div>
        <div className={styles.count}>
          <p className={styles.number}>4.</p>
          <p>митапы</p>
        </div>
      </div>
    </div>
  );
};
export default Events;
