import styles from './Konkurs.module.scss';
import { ReactComponent as LISVG } from '../../../../../../assets/img/cluster/rezidents/programms/liGreen.svg';
import { getDocs } from '../../../../../../redux/requests/getDocs';
import { useState, useEffect } from 'react';

const Konkurs = () => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    getDocs().then((data) => setDocs(data?.results));
  }, []);

  return (
    <div className={styles.content}>
      <ul className={styles.firstList}>
        <li>
          <LISVG />
          <p>НАЛИЧИЕ КРЕАТИВНОГО ПРОДУКТА (ПРОДУКЦИИ), КОТОРЫЙ ВЫПУСКАЕТСЯ</p>
        </li>
        <li>
          <LISVG />
          <p>СООТВЕТСТВИЕ НАПРАВЛЕНИЮ (СФЕРЫ) КРЕАТИВНЫХ ИНДУСТРИЙ</p>
        </li>
        <li>
          <LISVG />
          <p>
            ХАРАКТЕРИСТИКИ, СВИДЕТЕЛЬСТВУЮЩИЕ О НОВИЗНЕ И УНИКАЛЬНОСТИ
            КРЕАТИВНОГО ПРОДУКТА (ПРОДУКЦИИ)
          </p>
        </li>
        <li>
          <LISVG />
          <p>СТЕПЕНЬ ПРОРАБОТАННОСТИ ПРОЕКТНОЙ ИНИЦИАТИВЫ</p>
        </li>
        <li>
          <LISVG />
          <p>НАЛИЧИЕ КООПЕРАЦИОННЫХ СВЯЗЕЙ (СОВМЕСТНЫХ КЛАСТЕРНЫХ ПРОЕКТОВ)</p>
        </li>
      </ul>
      <div className={styles.documentation}>
        <p className={styles.text}>документация</p>
        <ul className={styles.secondList}>
          {docs.map((doc) => (
            <li key={doc.id}>
              <a target='_blank' href={doc.document}>
                {doc.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.questWrapper}>
        <p className={styles.quest}>Остались вопросы?</p>
        <script data-b24-form='click/17/1oqx96' data-skip-moving='true'>
          {(function (w, d, u) {
            var s = d.createElement('script');
            s.async = true;
            s.src = u + '?' + ((Date.now() / 180000) | 0);
            var h = d.getElementsByTagName('script')[0];
            h.parentNode.insertBefore(s, h);
          })(
            window,
            document,
            'https://cdn-ru.bitrix24.ru/b24021132/crm/form/loader_17.js'
          )}
        </script>
        <button className={styles.questButton}>/Получить консультацию</button>
      </div>
    </div>
  );
};
export default Konkurs;
