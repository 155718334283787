import classNames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './Table.module.scss';
import { formatDateToYYYYMMDD } from '../ClusterArenda';
import TableCeil from './TableCeil/TableCeil';

const Table = ({ booking, startDate, addBookingDays, setAddBookingDays }) => {
  const [weekDays, setWeekDays] = useState();
  const [bookingDays, setBookingDays] = useState();

  useEffect(() => {
    if (!startDate) return;
    const tmp = [];
    const num = startDate.getDay();
    if (num > 0) {
      for (let i = 0; i < 7; i++) {
        const date = new Date(startDate);
        if (i < num) {
          tmp.push(new Date(date.setDate(startDate.getDate() - num + i + 1)));
        } else {
          tmp.push(new Date(date.setDate(startDate.getDate() + i - num + 1)));
        }
      }
    } else {
      for (let i = 0; i < 7; i++) {
        const date = new Date(startDate);
        if (i < num) {
          tmp.push(new Date(date.setDate(startDate.getDate() - 6 + i)));
        } else {
          tmp.push(new Date(date.setDate(startDate.getDate() + i - 6)));
        }
      }
    }

    const tmp2 = [];
    for (let i = 0; i < 7; i++) {
      const bookingDay = booking.find(
        (day) => day.date == formatDateToYYYYMMDD(tmp[i])
      );
      tmp2.push(bookingDay);
    }
    setWeekDays(tmp);
    setBookingDays(tmp2);
  }, [startDate]);

  const addDate = (time, index) => {
    let tmp = addBookingDays;
    const day = tmp.find(
      (item) => item?.date == formatDateToYYYYMMDD(weekDays[index])
    );

    if (day && day?.hours.find((hour) => hour == time)) {
      day.hours = day.hours.filter((hour) => hour !== time);

      if (day.hours.length == 0) {
        tmp = tmp.filter((item) => item.date !== day.date);
      }
    } else if (day) {
      day.hours.push(time);
    } else {
      tmp.push({ date: formatDateToYYYYMMDD(weekDays[index]), hours: [time] });
    }

    setAddBookingDays([...tmp]);
  };

  if (!weekDays) return;
  
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.firstHead}>
          <td className={classNames(styles.first, styles.firstHead)}>дни</td>
          <td className={styles.firstHead}>пн</td>
          <td className={styles.firstHead}>вт</td>
          <td className={styles.firstHead}>ср</td>
          <td className={styles.firstHead}>чт</td>
          <td className={styles.firstHead}>пт</td>
          <td className={styles.firstHead}>сб</td>
          <td className={classNames(styles.last, styles.firstHead)}>вс</td>
        </tr>
        <tr>
          <td className={styles.first}>часы</td>
          <td>{weekDays[0].getDate()}</td>
          <td>{weekDays[1].getDate()}</td>
          <td>{weekDays[2].getDate()}</td>
          <td>{weekDays[3].getDate()}</td>
          <td>{weekDays[4].getDate()}</td>
          <td>{weekDays[5].getDate()}</td>
          <td className={styles.last}>{weekDays[6].getDate()}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className={styles.first}>9:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'9:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>10:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'10:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>11:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'11:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>12:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'12:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>13:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'13:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>14:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'14:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>15:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'15:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>16:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'16:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>17:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'17:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>18:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'18:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>19:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'19:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>20:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'20:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
        <tr>
          <td className={styles.first}>21:00</td>
          {weekDays.map((_, index) => (
            <TableCeil
              key={index}
              index={index}
              time={'21:00'}
              add={addDate}
              bookingDays={bookingDays}
              weekDays={weekDays}
              addBookingDays={addBookingDays}
              last={index == 6 ? true : false}
            />
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default Table;
