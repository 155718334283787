import React, { useEffect, useState } from "react";
import styles from "../MainPage/MainPage.module.scss";

import Container from "../../../templates/Container";
import PageTemplate from "../../../templates/PageTemplate";

import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";

import MainPageContent from "../MainPage/MainPaigeComponents/MainPageContent";
import MobileMainPageContent from "../MainPage/MainPaigeComponents/MobileMainPageContent";
import ColorWrapper from "../../../elements/ColorWrapper/ColorWrapper";
import MasonryBlock from "../../../elements/MasonryBlock/MasonryBlock";
import ExhibitionImage from "../../../elements/ExhibitionImage/ExhibitionImage";

import { ReactComponent as GoBackArrow } from "../../../../assets/img/arrow-left.svg";
import { ReactComponent as MobileGoBackArrow } from "../../../../assets/img/showroom/goBackMobile.svg";

import useMobileDetect from "use-mobile-detect-hook";

const SingleArchivePage = () => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  let singleArchivePageId = useParams();

  const data = useSelector((state) => state.exhibitions.exhibitions);

  const [dataCurrentPage, setDataCurrentPage] = useState(0);

  useEffect(() => {
    setDataCurrentPage(
      data.find((item) => item.id === +singleArchivePageId.id)
    );
  }, [data]);

  const COLORS = ["#4F4B98", "#F0E400", "#2CA99B", "#D6358C"];
  const IMAGES_PER_PAGE = 12;

  let parsePicturesArray = (array, pagesQuantity, picturesPerPage) => {
    let arrayCopy = [...array];
    let rez = [];
    for (let i = 0; i < pagesQuantity; i++) {
      let page = [];
      for (let i = 0; i < picturesPerPage; i++) {
        page.push(arrayCopy.shift());
      }
      rez.push(page);
    }

    return rez;
  };

  let pages = [];
  if (dataCurrentPage) {
    let exhibition_pictures = dataCurrentPage.pictures;
    let pagesQuantity = Math.ceil(exhibition_pictures.length / IMAGES_PER_PAGE);
    pages = parsePicturesArray(
      exhibition_pictures,
      pagesQuantity,
      IMAGES_PER_PAGE
    );
  }

  let getNextColor = (pageIndex) => {
    return COLORS[pageIndex % COLORS.length];
  };

  return (
    <>
      <PageTemplate>
        <canvas id="noisy-canvas" className={styles.noisy_canvas}></canvas>
        <Container>
          {/* стрелка назад */}
          <div className={styles.backArrow}>
            {isMobile ? (
              <Link to={`/archive/`}>
                {" "}
                <MobileGoBackArrow />
              </Link>
            ) : (
              <Link to={`/archive/`}>
                {" "}
                <GoBackArrow /> Назад
              </Link>
            )}
          </div>

          {dataCurrentPage &&
            (isMobile ? (
              <MobileMainPageContent
                activeExhibition={0}
                setActiveExhibition={() => {}}
                exhibitionsCount={1}
                data={dataCurrentPage}
                sliderContent={dataCurrentPage.description}
                marginTop={true}
              />
            ) : (
              <MainPageContent
                activeExhibition={0}
                setActiveExhibition={() => {}}
                exhibitionsCount={1}
                data={dataCurrentPage}
                sliderContent={dataCurrentPage.description}
                marginTop={true}
              />
            ))}
        </Container>

        {dataCurrentPage && (
          <>
            {pages.map((page, index) => {
              return (
                <ColorWrapper
                  color={getNextColor(index)}
                  key={index}
                  activeExhibition={0}
                >
                  <MasonryBlock>
                    {page.map(
                      (item, index) =>
                        item && (
                          <ExhibitionImage
                            key={index}
                            url={item.thumbnail}
                            author={item.author}
                            name={item.title}
                            id={item.id}
                          />
                        )
                    )}
                  </MasonryBlock>
                </ColorWrapper>
              );
            })}
          </>
        )}
      </PageTemplate>
    </>
  );
};

export default SingleArchivePage;
