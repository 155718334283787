import React, { useEffect, useState } from 'react';
import styles from './CouponsUsage.module.scss';
import { useNavigate } from 'react-router-dom';
import { getCouponsUsage } from '../../../redux/requests/getCouponsUsage';

const CouponsUsage = () => {
  const navigate = useNavigate();
  const [couponsUsage, setCouponsUsage] = useState([]);

  const convertDate = (inputDateString) => {
    const inputDate = new Date(inputDateString);
    inputDate.setUTCHours(inputDate.getUTCHours() + 7);

    const day = inputDate.getUTCDate().toString().padStart(2, '0');
    const month = (inputDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = inputDate.getUTCFullYear();
    const hours = inputDate.getUTCHours().toString().padStart(2, '0');
    const minutes = inputDate.getUTCMinutes().toString().padStart(2, '0');
    const seconds = inputDate.getUTCSeconds().toString().padStart(2, '0');

    const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  useEffect(() => {
    let token = '';
    if (window.localStorage.getItem('token')) {
      token = window.localStorage.getItem('token');
    }
    if (window.sessionStorage.getItem('token')) {
      token = window.sessionStorage.getItem('token');
    }
    if (!token) {
      navigate('/');
    } else {
      getCouponsUsage(token).then((res) => {
        setCouponsUsage(res);
      });
    }
  }, []);
  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <table className={styles.couponsTable}>
          <thead>
            <tr>
              <th>Название акции</th>
              <th>Дата списания</th>
              <th>Кто списал</th>
              <th>Имя пользователя</th>
            </tr>
          </thead>
          <tbody>
            {couponsUsage.map((item, index) => (
              <tr key={index} className={styles.coupons}>
                <td>{item.coupon}</td>
                <td>{convertDate(item.date)}</td>
                <td>{item.manager}</td>
                <td>{item.user}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CouponsUsage;
