import * as React from 'react';
import {
  MdPlayArrow,
  MdPause,
  MdSkipNext,
  MdSkipPrevious,
  MdVolumeUp,
  MdVolumeOff,
} from 'react-icons/md';
import { CgSpinner } from 'react-icons/cg';
import AudioProgressBar from '../AudioProgressBar/AudioProgressBar';
import IconButton from '../IconButton/IconButton';
import VolumeInput from '../VolumeInput/VolumeInput';
import { useState, useEffect } from 'react';

import styles from './AudioPlayer.module.scss';

function formatDurationDisplay(duration) {
  const min = Math.floor(duration / 60);
  const sec = Math.floor(duration - min * 60);

  const formatted = [min, sec].map((n) => (n < 10 ? '0' + n : n)).join(':');

  return formatted;
}

export default function AudioPlayer({
  currentSong,
  songCount,
  songIndex,
  onNext,
  onPrev,
  audioPlayerRef,
}) {
  const audioRef = audioPlayerRef;
  const [isReady, setIsReady] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [buffered, setBuffered] = useState(0);
  const [volume, setVolume] = useState(0.2);
  const [isPlaying, setIsPlaying] = useState(false);

  const durationDisplay = formatDurationDisplay(duration);
  const elapsedDisplay = formatDurationDisplay(currentProgress);

  useEffect(() => {
    audioRef.current?.load();
    audioRef.current?.pause();

    const timeout = setTimeout(() => {
      audioRef.current?.play();
      audioRef.current?.pause();
      setIsReady(true);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [songIndex]);

  const handleNext = () => {
    onNext();
  };

  const handlePrev = () => {
    onPrev();
  };

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      audioRef.current?.play();
      setIsPlaying(true);
    }
  };

  const handleBufferProgress = (e) => {
    const audio = e.currentTarget;
    const dur = audio.duration;
    if (dur > 0) {
      for (let i = 0; i < audio.buffered.length; i++) {
        if (
          audio.buffered.start(audio.buffered.length - 1 - i) <
          audio.currentTime
        ) {
          const bufferedLength = audio.buffered.end(
            audio.buffered.length - 1 - i
          );
          setBuffered(bufferedLength);
          break;
        }
      }
    }
  };

  const handleMuteUnmute = () => {
    if (!audioRef.current) return;

    if (audioRef.current.volume !== 0) {
      audioRef.current.volume = 0;
    } else {
      audioRef.current.volume = 1;
    }
  };

  const handleVolumeChange = (volumeValue) => {
    if (!audioRef.current) return;
    audioRef.current.volume = volumeValue;
    setVolume(volumeValue);
  };

  return (
    <div className={styles.audioPlayer}>
      {currentSong && (
        <audio
          ref={audioRef}
          onDurationChange={(e) => setDuration(e.currentTarget.duration)}
          onPlaying={() => setIsPlaying(true)}
          onPause={() => setIsPlaying(false)}
          onEnded={handleNext}
          // onCanPlay={(e) => {
          //   e.currentTarget.volume = volume;
          //   setIsReady(true);
          // }}
          onTimeUpdate={(e) => {
            setCurrentProgress(e.currentTarget.currentTime);
            handleBufferProgress(e);
          }}
          onProgress={handleBufferProgress}
          onVolumeChange={(e) => setVolume(e.currentTarget.volume)}
        >
          <source
            type='audio/mpeg'
            src={currentSong.src ? currentSong.src : currentSong.audio}
          />
        </audio>
      )}
      <AudioProgressBar
        duration={duration}
        currentProgress={currentProgress}
        buffered={buffered}
        onChange={(e) => {
          if (!audioRef.current) return;

          audioRef.current.currentTime = e.currentTarget.valueAsNumber;

          setCurrentProgress(e.currentTarget.valueAsNumber);
        }}
      />

      <div className={styles.wrapper}>
        <div className={styles.wrapperInner}>
          <p className={styles.songName}>
            {currentSong?.title ?? 'Выберите песню'}
          </p>
          <p className={styles.author}>{currentSong?.singer}</p>
        </div>
      </div>
      <div className={styles.iconsWrapper}>
        <span className={styles.author}>
          {elapsedDisplay} / {durationDisplay}
        </span>
        <div className={styles.icons}>
          <IconButton
            onClick={handlePrev}
            disabled={songIndex === 0}
            aria-label='к предыдущему'
            intent='secondary'
          >
            <MdSkipPrevious size={24} />
          </IconButton>
          <IconButton
            disabled={!isReady}
            onClick={togglePlayPause}
            aria-label={isPlaying ? 'Пауза' : 'Плей'}
            size='lg'
          >
            {!isReady && currentSong ? (
              <CgSpinner size={24} className={styles.spin} />
            ) : isPlaying ? (
              <MdPause size={30} />
            ) : (
              <MdPlayArrow size={30} />
            )}
          </IconButton>
          <IconButton
            onClick={handleNext}
            disabled={songIndex === songCount - 1}
            aria-label='к следующему'
            intent='secondary'
          >
            <MdSkipNext size={24} />
          </IconButton>
        </div>

        <div className={styles.mute}>
          <IconButton
            intent='primary'
            size='sm'
            onClick={handleMuteUnmute}
            aria-label={volume === 0 ? 'unmute' : 'mute'}
          >
            {volume === 0 ? (
              <MdVolumeOff size={20} />
            ) : (
              <MdVolumeUp size={20} />
            )}
          </IconButton>
          <VolumeInput volume={volume} onVolumeChange={handleVolumeChange} />
        </div>
      </div>
    </div>
  );
}
