import { useState } from 'react';
import { createContext } from 'react';

const StateContext = createContext();

function StateProvider({ children }) {
  const [categoryState, setCategoryState] = useState('');

  return (
    <StateContext.Provider
      value={{
        categoryState,
        setCategoryState,
      }}
    >
      {children}
    </StateContext.Provider>
  );
}

export { StateProvider, StateContext };
