import React, { useEffect, useState } from 'react';
import styles from './EventSection.module.scss';

import Container from '../../../templates/Container';
import PromoSlider from './Slider/PromoSlider';
// import { getEvents } from '../../../../redux/requests/getEvents';

import useMobileDetect from 'use-mobile-detect-hook';

const EventSection = (props) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();


  return (
    props.promoTitle && (
      <div
        className={isMobile ? `${styles.promo} ${styles.promo__mobile}` : styles.promo}
        style={{ backgroundImage: `url('${props.backgroundImage}')` }}>
        <Container>
          <div className={isMobile ? `${styles.container} ${styles.container__mobile}` : styles.container}>
            <h1 className={isMobile ? `${styles.promo_title} ${styles.promo_title__mobile}` : styles.promo_title}>
              {props.promoTitle}
            </h1>

            <div className={isMobile ? `${styles.info} ${styles.info__mobile}` : styles.info}>
              <p className={isMobile ? `${styles.dates} ${styles.dates__mobile}` : styles.dates}>{props.promoDates}</p>
              <p className={isMobile ? `${styles.city} ${styles.city__mobile}` : styles.city}>{props.promoCity}</p>
              <p className={isMobile ? `${styles.place} ${styles.place__mobile}` : styles.place}>{props.promoPlace}</p>
              {props.registrationLink && (
                <a
                  href={props.registrationLink}
                  className={isMobile ? `${styles.regisrtation} ${styles.regisrtation__mobile}` : styles.regisrtation}>
                  Регистрация
                </a>
              )}

              {/* <div className={styles.slider}>
              {logos.map((item) => {
                return <img src={item} alt="logo" />;
              })}
            </div> */}
            </div>
            <div className={isMobile ? `${styles.slider} ${styles.slider__mobile}` : styles.slider}>
              <PromoSlider slides={props.logos} />
            </div>
          </div>
        </Container>
      </div>
    )
  );
};

export default EventSection;
