import React, { useEffect, useState } from 'react';
import styles from './ShopPage.module.scss';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import PageTemplate from '../../../templates/PageTemplate';
import Container from '../../../templates/Container';
import { getShop } from '../../../../redux/requests/getShop';
import { getProduct } from '../../../../redux/requests/getProduct';
import useMobileDetect from 'use-mobile-detect-hook';
import { ReactComponent as GoBackArrow } from '../../../../assets/img/arrow-left.svg';
import { ReactComponent as MobileGoBackArrow } from '../../../../assets/img/showroom/goBackMobile.svg';
import shopPlaceholder from '../../../../assets/img/square_placeholder.jpg';
import productPlaceholder from '../../../../assets/img/square_placeholder.jpg';

const ShopPage = ({ addProductToOrder, orderDict }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  // let [isButtonActive, setIsButtonActive] = useState(true);

  let params = useParams();

  let [shop, setShop] = useState({});
  let location = useLocation();
  let [product, setProduct] = useState({});

  useEffect(() => {
    getProduct(params.id).then((res) => {
      setProduct(res);
    });

    getShop(params.id).then((res) => {
      setShop(res);
    });
  }, [location, orderDict]);

  const navigate = useNavigate();

  const clickOrLink = (e, id) => {
    if (e.target.className.includes('productButton')) {
      return;
    }
    return navigate(`/showroom/products/` + id);
  };

  return (
    <div className={styles.wrapper}>
      <PageTemplate
        header='absolute'
        orderLink='/showroom/order'
        order={true}
        orderDict={orderDict}
      >
        <Container>
          {!isMobile && shop.category && (
            <div className={styles.backLink}>
              <Link to={`/showroom/categories/${shop.category}`}>
                <>
                  <GoBackArrow /> Назад
                </>
              </Link>
            </div>
          )}
          {isMobile && (
            <Link
              className={styles.backLink}
              to={`/showroom/categories/${shop.category}`}
            >
              <>
                <MobileGoBackArrow />
              </>
            </Link>
          )}
          <div className={styles.shop}>
            <div className={styles.shopInfo}>
              <div className={styles.shopImage}>
                <img src={shop.cropped_image || shopPlaceholder} alt='' />
              </div>
              <div className={styles.shopTitle}> {shop.title}</div>
              {shop.description && (
                <div className={styles.shopDescription}>{shop.description}</div>
              )}
            </div>
            <div className={styles.products}>
              {shop.products &&
                shop.products.map((product) => (
                  // <Link
                  //   key={product.id}
                  //   className={styles.productLink}
                  //   to={`/showroom/products/${product.id}`}
                  // >

                  <div
                    key={product.id}
                    className={styles.productLink}
                    // to={`/showroom/products/${product.id}`}
                    onClick={(e) => clickOrLink(e, product.id)}
                  >
                    <div className={styles.productWrapper}>
                      <div className={styles.product}>
                        <div className={styles.productReadMore}>Подробнее</div>
                        <div className={styles.productInfo}>
                          <div className={styles.productTitle}>
                            {product.title}
                          </div>
                          <div className={styles.productCost}>
                            {String(product.price).replace(
                              /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
                              '$1 '
                            )}
                            р.
                          </div>
                          {/* <button
                            type="button"
                            className={styles.productButton}
                          >
                            Купить
                          </button> */}

                          {/* <button
                            type="button"
                            className={styles.productButton}
                            disabled={(product.quantity < 1) | !isButtonActive}
                            onClick={() => {
                              // openModal();
                              addProductToOrder(product);
                            }}
                          >
                            {product.quantity > 0
                              ? isButtonActive
                                ? 'Купить'
                                : 'В корзине'
                              : 'Нет в наличии'}
                          </button> */}
                        </div>
                        <div className={styles.imageWrapper}>
                          <img
                            src={`${
                              product.cropped_image || productPlaceholder
                            }`}
                            alt={product.title}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </Container>
      </PageTemplate>
    </div>
  );
};

export default ShopPage;
