import React, { useEffect, useState } from 'react';

import styles from './OrderPage.module.scss';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  decrementShowroomAction,
  incrementShowroomAction,
} from '../../../redux/reducers/showroomOrderReducer';
import {
  decrementAction,
  incrementAction,
} from '../../../redux/reducers/orderReducer';

const MobileInputNumber = ({ defaultValue, funcChange, quantity, product }) => {
  const [value, setValue] = useState(defaultValue);
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const decrement = () => {
    if (pathname.includes('showroom')) {
      dispatch(decrementShowroomAction(product));
    } else {
      dispatch(decrementAction(product));
    }
  };
  const increment = () => {
    if (pathname.includes('showroom')) {
      dispatch(incrementShowroomAction(product));
    } else {
      dispatch(incrementAction(product));
    }
  };

  // useEffect(() => {
  //   funcChange(value);
  // }, [value]);

  return (
    <div className={styles.mobile_value_filed}>
      <button
        onClick={product.count <= 1 ? null : decrement}
        className={
          value <= 1 ? `${styles.minus} ${styles.disabled}` : styles.minus
        }
      >
        -
      </button>
      {product.count}
      <button
        onClick={product.count >= quantity ? null : increment}
        className={
          value >= quantity ? `${styles.plus} ${styles.disabled}` : styles.plus
        }
      >
        +
      </button>
    </div>
  );
};

export default MobileInputNumber;
