import React, { useState } from 'react';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styles from '../ProfilePage.module.scss';
import { MEDIA_URL } from '../../../../../redux/API/BASE_URL';
import useMobileDetect from 'use-mobile-detect-hook';

const ProfileSlider = ({ additional_photos, main_photo }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();
  const [slider1, serSlider1] = useState();
  const [slider2, serSlider2] = useState();

  let settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: isMobile ? true : false,
  };

  let settings2 = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: isMobile ? true : false,
    focusOnSelect: true,
  };

  const handleNonSlideMiniatureClick = (thumb) => {
    const arr = [{ thumbnail: main_photo }, ...additional_photos];
    const clickedImageIndex = arr.findIndex((item) => item.thumbnail === thumb);

    slider1.slickGoTo(clickedImageIndex);
  };

  return (
    <div
      id='promoSlider'
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Slider
        {...settings}
        ref={(slider) => serSlider1(slider)}
        asNavFor={slider2}
      >
        {[{ thumbnail: main_photo }, ...additional_photos].map(
          (item, index) => (
            <div key={index} className={styles.slide}>
              <img src={MEDIA_URL + item.thumbnail} alt='работа' />
            </div>
          )
        )}
      </Slider>
      <div
        className={styles.miniSlider}
        id='miniSlider'
        style={{
          minWidth:
            additional_photos.length + 1 >= 4
              ? 4
              : (additional_photos.length + 1) * 90,
          maxWidth: 4 * 82,
        }}
      >
        {additional_photos.length + 1 >= 4 ? (
          <Slider
            {...settings2}
            ref={(slider) => serSlider2(slider)}
            asNavFor={slider1}
          >
            {[{ thumbnail: main_photo }, ...additional_photos].map(
              (item, index) => (
                <div key={index} className={styles.slide}>
                  <img
                    className={styles.miniature}
                    src={MEDIA_URL + item.thumbnail}
                    alt='работа'
                  />
                </div>
              )
            )}
          </Slider>
        ) : (
          <div
            className={styles.nonSlideMiniature}
            style={{
              gridTemplateColumns: `repeat(${
                additional_photos.length + 1
              }, 1fr)`,
            }}
          >
            {[{ thumbnail: main_photo }, ...additional_photos].map(
              (item, index) => (
                <div
                  key={index}
                  className={styles.slide}
                  onClick={() => handleNonSlideMiniatureClick(item.thumbnail)}
                >
                  <img
                    className={styles.miniature}
                    src={MEDIA_URL + item.thumbnail}
                    alt='работа'
                  />
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSlider;
