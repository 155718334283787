import { ReactComponent as Web } from '../../../../../assets/img/web.svg';
import { ReactComponent as Tg } from '../../../../../assets/img/tg.svg';
import { ReactComponent as Vk } from '../../../../../assets/img/vk.svg';

import styles from './Card.module.scss';
import { useNavigate } from 'react-router-dom';

const Card = ({ resident }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/residents/${resident.id}`);
      }}
      className={styles.card}
    >
      <img src={resident.logo} />
      <p className={styles.cardTitle}>{resident.title}</p>
      {/* <div className={styles.social}>
        <Vk />
        <Tg />
        <Web />
      </div>
      <button onClick={() => setIsOpen(true)} className={styles.cardButton}>
        Подробнее
      </button> */}
    </div>
  );
};

export default Card;
