/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PageTemplate from '../../templates/PageTemplate';
import Container from '../../templates/Container';

import styles from './AllExhibitionsPage.module.scss';
import { useSelector } from 'react-redux';
import ExhibitionsItem from './ExhibitionsItem/ExhibitionsItem';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';

const AllExhibitionsPage = () => {
  const [exhibitions, setExhibitions] = useState([]);
  const [containerWidth, setContainerWidth] = useState(false);
  const [isMouseOnSlider, setIsMouseOnSlider] = useState(false);
  const exhibitionsRedux = useSelector(
    (state) => state.exhibitions.exhibitions
  );

  const exhibitionContainer = useRef(null);
  const sliderRef = useRef(null);

  let colorCount = 0;

  const setNextColor = () => {
    const colors = ['#D6338A', '#2AA89A', '#4F4C93', '#F0E400'];
    const result = colors[colorCount];

    if (colorCount === 3) {
      colorCount = 0;
    } else {
      colorCount++;
    }
    return result;
  };

  useLayoutEffect(() => {
    setContainerWidth(exhibitionContainer.current.clientWidth);
  }, [exhibitionContainer.current]);

  const scrollHandler = (e) => {
    if (e.deltaY > 0) {
      sliderRef.current.slickNext();
    } else {
      sliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    const block = document.getElementById('root');

    if (isMouseOnSlider) {
      block.style.overflowY = 'hidden';
      block.style.paddingRight = '5px';
    } else {
      block.style.overflowY = 'scroll';
      block.style.paddingRight = '0px';
    }
  }, [isMouseOnSlider]);

  useEffect(() => {
    setExhibitions(exhibitionsRedux);
  }, [exhibitionsRedux]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
  };

  return (
    <PageTemplate>
      <Container>
        <div className={styles.wrapper}>
          <h2>Выставки</h2>
        </div>
      </Container>

      <div
        onWheel={scrollHandler}
        onMouseOver={() => setIsMouseOnSlider(true)}
        onMouseLeave={() => setIsMouseOnSlider(false)}
        ref={exhibitionContainer}
        className={styles.exhibitiosList}
      >
        {window.innerWidth < 768 ? (
          exhibitions.map((item) => {
            return (
              <ExhibitionsItem
                key={item.id}
                item={item}
                width={containerWidth && containerWidth / 1.5}
                background={setNextColor}
              />
            )
          })
        ) : (
          <Slider {...settings} ref={sliderRef}>
            {exhibitions.map((item) => (
              <ExhibitionsItem
                key={item.id}
                item={item}
                width={containerWidth && containerWidth / 5}
              />
            ))}
          </Slider>
        )}
      </div>
    </PageTemplate>
  );
};

export default AllExhibitionsPage;
