const initialState = {
  exhibitions: [],
  activeExhibitionIndex: false,
};

const GET_EXHIBITION = "GET_EXHIBITION";
const SET_ACTIVE_EXHIBITION_INDEX = "SET_ACTIVE_EXHIBITION_INDEX";


export const exhibitionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXHIBITION:
      return { ...state, exhibitions: action.payload.results };

    case SET_ACTIVE_EXHIBITION_INDEX:
      return { ...state, activeExhibitionIndex: action.payload };

    default:
      return state;
  }
};

export const getExhibitionAction = (payload) => ({
  type: GET_EXHIBITION,
  payload,
});
export const setActiveExhibitionIndexAction = (payload) => ({
  type: SET_ACTIVE_EXHIBITION_INDEX,

  payload,
});
