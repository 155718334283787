import styles from './Rezidentstvo.module.scss';
import Rezidentstvo1 from '../../../../../../assets/img/cluster/rezidents/rez1.svg';
import Rezidentstvo2 from '../../../../../../assets/img/cluster/rezidents/rez2.svg';
import Rezidentstvo3 from '../../../../../../assets/img/cluster/rezidents/rez3.svg';
import { ReactComponent as Arrow } from '../../../../../../assets/img/cluster/rezidents/arrow.svg';

const Rezidentstvo = () => {
  return (
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <div className={styles.textwithSVG}>
          <img src={Rezidentstvo1} />
          <p>льготная арендная ставка и система сервисов</p>
        </div>
        <div className={styles.textwithSVG}>
          <img src={Rezidentstvo2} />
          <p>ПРОГРАММА РЕЗИДЕНТСТВА - МЕРЫ ПОДДЕРЖКИ НА РАЗВИТИЕ ПРОЕКТОВ</p>
        </div>
        <div className={styles.textwithSVG}>
          <img src={Rezidentstvo3} />
          <p>комплекс внутренних ПОДДЕРЖИВАЮЩИХ Событий</p>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <p className={styles.textRez}>КОНКУРСНЫЙ ОТБОР </p>
        <Arrow />
        <p className={styles.textRez}>СТАТУС РЕЗИДЕНТ КРЕАТИВНОГО КЛАСТЕРА</p>
        <Arrow />
        <p className={styles.textRez}>ДОГОВОР АРЕНДЫ НЕЖИЛОГО ПОМЕЩЕНИЯ</p>
        <Arrow />
        <p className={styles.textRez}>
          СОГЛАШЕНИЕ О РЕАЛИЗАЦИИ ПРОЕКТА В РАМКАХ ПРОГРАММЫ РЕЗИДЕНСТВА
        </p>
      </div>
    </div>
  );
};
export default Rezidentstvo;
