import React, { useEffect } from 'react';
import styles from './ModalPicture.module.scss';

const ModalPicture = ({ image, setModalActive, modalActive }) => {

  return (
    <div className={modalActive ? `${styles.modal} ${styles.modal__active}` : styles.modal} onClick={() => setModalActive(false)}>

      <div className={modalActive ? `${styles.modal_content} ${styles.modal_content__active}` : styles.modal_content}>

        <div>
            <img src={image} alt="" onClick={e => e.stopPropagation()} />

            <button type='button' onClick={() => {
              setModalActive(false);
            }}></button>

        </div>
      </div>
    </div>
  )
}

export default ModalPicture