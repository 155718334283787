import { BASE_URL_V2 } from '../API/BASE_URL';
import { couponUsage } from './couponUsage';

export const auth = async (user, checked, userId, couponId, hashString) => {
  const response = await fetch(BASE_URL_V2 + 'auth/token/login/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(user),
  });
  if (!response.ok) {
    const data = await response.json();
    return data;
  } else {
    const data = await response.json();
    if (checked) {
      window.localStorage.setItem('token', data.auth_token);
    } else {
      window.sessionStorage.setItem('token', data.auth_token);
    }

    if (hashString) {
      let token = '';
      if (window.localStorage.getItem('token')) {
        token = window.localStorage.getItem('token');
      }
      if (window.sessionStorage.getItem('token')) {
        token = window.sessionStorage.getItem('token');
      }
      const res = await couponUsage(token, userId, couponId);
      return res;
    }
  }
};
