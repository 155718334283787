import { useEffect, useState } from 'react';

export const useHashFragment = (offset = 0, trigger = true) => {
  useEffect(() => {
    const scrollToHashElement = () => {
      const { hash } = window.location;
      const elementToScroll = document.getElementById(hash?.replace('#', ''));
      if (!elementToScroll) return;

      document.querySelector('#root').scrollTo({
        top: elementToScroll.offsetTop - offset,
        behavior: 'smooth',
      });
      setTimeout(() => {
        if (elementToScroll.getBoundingClientRect().top > 300) {
          scrollToHashElement();
        }
      }, 700);
    };
    if (!trigger) return;

    scrollToHashElement();
    window.addEventListener('hashchange', scrollToHashElement);
    return () => window.removeEventListener('hashchange', scrollToHashElement);
  }, [trigger]);
};

export const useOnLoadImages = (ref) => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    const updateStatus = (images) => {
      setStatus(
        images.map((image) => image.complete).every((item) => item === true)
      );
    };

    if (!ref?.current) return;

    const imagesLoaded = Array.from(ref.current.querySelectorAll('img'));

    if (imagesLoaded.length === 0) {
      setStatus(true);
      return;
    }

    imagesLoaded.forEach((image) => {
      image.addEventListener('load', () => updateStatus(imagesLoaded), {
        once: true,
      });
      image.addEventListener('error', () => updateStatus(imagesLoaded), {
        once: true,
      });
    });

    return;
  }, [ref]);

  return status;
};
