import { useState, useEffect } from 'react';
import Card from './Card/Card';
// import { ReactComponent as Tg } from '../../../../assets/img/tg.svg';
// import { ReactComponent as Vk } from '../../../../assets/img/vk.svg';
// import { ReactComponent as Web } from '../../../../assets/img/web.svg';
// import { ReactComponent as Email } from '../../../../assets/img/mail.svg';
// import { ReactComponent as Tel } from '../../../../assets/img/tel.svg';
import styles from './Rezidents.module.scss';
import { getResidents } from '../../../../redux/requests/getResidents';
import classNames from 'classnames';
// import { ReactComponent as GoBackArrow } from '../../../../assets/img/arrow-left.svg';
// import { ReactComponent as MobileGoBackArrow } from '../../../../assets/img/showroom/goBackMobile.svg';
import useMobileDetect from 'use-mobile-detect-hook';
// import { useRef } from 'react';
// import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSlider.scss';
import { useContext } from 'react';
import { StateContext } from '../../../../context/StateContext';
// import { useLocation } from 'react-router-dom';

const Rezidents = () => {
  const { categoryState, setCategoryState } = useContext(StateContext);
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();
  const [residents, setResidents] = useState([]);
  const [residentCategories, setResidentCategories] = useState([]);
  const [filterCategory, setCategory] = useState(categoryState);
  const settings = {
    dotsClass: 'dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    getResidents().then((data) => setResidents(data));
  }, []);
  useEffect(() => {
    let categories = Array.from(
      new Set(residents.flatMap((obj) => obj.classification))
    );

    setResidentCategories(categories);
  }, [residents]);

  return (
    <section id='residentsList' className={styles.rezidents}>
      <p className={styles.title}> Резиденты креативного кластера</p>
      <div className={styles.categories}>
        <p
          onClick={() => {
            setCategory('');
            setCategoryState('');
          }}
          className={classNames(styles.category, {
            [styles.categoryActive]: filterCategory == '',
          })}
        >
          ВСЕ
        </p>
        {residentCategories.sort().map((category, id) => (
          <p
            onClick={() => {
              setCategory(category);
              setCategoryState(category);
            }}
            className={classNames(styles.category, {
              [styles.categoryActive]: category == filterCategory,
            })}
            key={id}
          >
            {category}
          </p>
        ))}
      </div>
      <div className={styles.cards}>
        {residents
          .sort((a, b) => a.order_index - b.order_index)
          .reverse()
          .map((resident) =>
            !filterCategory ? (
              <Card key={resident.id} resident={resident} />
            ) : resident.classification == filterCategory ? (
              <Card key={resident.id} resident={resident} />
            ) : null
          )}
      </div>
    </section>
  );
};
export default Rezidents;
