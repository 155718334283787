import { useEffect } from 'react';

import styles from './Nav.module.scss';
import { Link } from 'react-router-dom';

const COLORS = ['#4F4B98', '#2AA89A', '#D6338A', '#F0E400'];

const Nav = () => {
  useEffect(() => {
    const root = document.querySelector('#root');
    const navs = document.querySelectorAll('nav ul li');
    const getRandomColor = () => {
      let randomNumber = Math.random() * COLORS.length;
      let randomNaturalNumber = Math.floor(randomNumber);
      return COLORS[randomNaturalNumber];
    };

    navs.forEach((nav) => {
      nav.addEventListener('click', () => {
        navs.forEach((nav) => {
          nav.classList.remove(`${styles.open}`);
          nav.querySelector('a').style.color = '#fff';
        });
        nav.classList.add(`${styles.open}`);
        nav.querySelector('a').style.color = getRandomColor();
      });
    });
    root.addEventListener('scroll', () => {
      if (
        document.querySelector('#cluster').getBoundingClientRect().y < 100 &&
        document.querySelector('#cluster').getBoundingClientRect().y > -100
      ) {
        navs.forEach((nav) => {
          nav.classList.remove(`${styles.open}`);
          nav.querySelector('a').style.color = '#fff';
        });
        navs[0].classList.add(`${styles.open}`);
        navs[0].querySelector('a').style.color = getRandomColor();
      } else if (
        document.querySelector('#residents').getBoundingClientRect().y < 100 &&
        document.querySelector('#residents').getBoundingClientRect().y > -100
      ) {
        navs.forEach((nav) => {
          nav.classList.remove(`${styles.open}`);
          nav.querySelector('a').style.color = '#fff';
        });
        navs[1].classList.add(`${styles.open}`);
        navs[1].querySelector('a').style.color = getRandomColor();
      } else if (
        document.querySelector('#arenda').getBoundingClientRect().y < 100 &&
        document.querySelector('#arenda').getBoundingClientRect().y > -100
      ) {
        navs.forEach((nav) => {
          nav.classList.remove(`${styles.open}`);
          nav.querySelector('a').style.color = '#fff';
        });
        navs[2].classList.add(`${styles.open}`);
        navs[2].querySelector('a').style.color = getRandomColor();
      } else if (
        document.querySelector('#events').getBoundingClientRect().y < 100 &&
        document.querySelector('#events').getBoundingClientRect().y > -100
      ) {
        navs.forEach((nav) => {
          nav.classList.remove(`${styles.open}`);
          nav.querySelector('a').style.color = '#fff';
        });
        navs[3].classList.add(`${styles.open}`);
        navs[3].querySelector('a').style.color = getRandomColor();
      }
    });
  }, []);
  // useEffect(() => {
  //   const page = document.querySelector('#page');
  //   const root = document.querySelector('#root');

  //   root.addEventListener('scroll', () => {
  //     console.log(page.getBoundingClientRect().height);
  //   });
  // }, []);

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <Link to='#cluster'>
            <span>о кластере</span>
          </Link>
        </li>
        <li>
          <Link to='#residents'>
            <span>резиденты</span>
          </Link>
        </li>
        <li>
          <Link to='#arenda'>
            <span>аренда</span>
          </Link>
        </li>
        <li>
          <Link to='#events'>
            <span>мероприятия</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};
export default Nav;
