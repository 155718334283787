import PageTemplate from '../../templates/PageTemplate';
import Nav from './Nav/Nav';
import AboutCluster from './AboutCluster/AboutCluster';
import Arenda from './Arenda/Arenda';
import Rezidents from './Rezidents/Rezidents';
import { useHashFragment } from '../../../hooks/scrollToLocation';

const Cluster = () => {
  useHashFragment();
  return (
    <PageTemplate isFooter={false}>
      <Nav />
      <AboutCluster />
      {/* <Rezidents /> */}
      {/* <Arenda /> */}
    </PageTemplate>
  );
};

export default Cluster;
