import React, { useEffect, useState } from "react";

import styles from "./OrderPage.module.scss";
import { useDispatch } from "react-redux";
import {
  decrementAction,
  incrementAction,
} from "../../../redux/reducers/orderReducer";
import {
  decrementShowroomAction,
  incrementShowroomAction,
} from "../../../redux/reducers/showroomOrderReducer";
import { useLocation } from "react-router-dom";

const InputNumber = ({ defaultValue, funcChange, quantity, product }) => {
  const [value, setValue] = useState(defaultValue);
  const { pathname } = useLocation();

  const decrement = () => {
    if (pathname.includes("showroom")) {
      dispatch(decrementShowroomAction(product));
    } else {
      dispatch(decrementAction(product));
    }
  };
  const increment = () => {
    if (pathname.includes("showroom")) {
      dispatch(incrementShowroomAction(product));
    } else {
      dispatch(incrementAction(product));
    }
  };

  const dispatch = useDispatch();

  // useEffect(() => {
  //   funcChange(value);
  // }, [value]);

  return (
    <div className={styles.input_number}>
      <div className={styles.value_filed}>
        <button
          onClick={product.count <= 1 ? null : decrement}
          className={
            product.count <= 1
              ? `${styles.minus} ${styles.disabled}`
              : styles.minus
          }
        ></button>

        <p className={styles.quantity}>{product.count}</p>

        <button
          onClick={product.count >= quantity ? null : increment}
          className={
            product.count >= quantity
              ? `${styles.plus} ${styles.disabled}`
              : styles.plus
          }
        ></button>
      </div>
    </div>
  );
};

export default InputNumber;
