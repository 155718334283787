import { combineReducers } from "redux";
import { exhibitionReducer } from "./reducers/exhibitionReducer";
import { orderReducer } from "./reducers/orderReducer";
import { showroomOrderReducer } from "./reducers/showroomOrderReducer";

export const rootReducer = combineReducers({
  exhibitions: exhibitionReducer,
  order: orderReducer,
  showroomOrder: showroomOrderReducer,
});
