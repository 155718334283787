import { CLUSTER_API } from '../API/BASE_URL';

export const getAudio = async () => {
  const response = await fetch(CLUSTER_API + 'about/audio/', {
    method: 'GET',
  });

  const data = await response.json();
  return data;
};
