import styles from './Services.module.scss';
import { ReactComponent as LISVG } from '../../../../../../assets/img/cluster/rezidents/programms/li.svg';
import { ReactComponent as Raket3 } from '../../../../../../assets/img/cluster/rezidents/programms/raket3.svg';
import { ReactComponent as Raket4 } from '../../../../../../assets/img/cluster/rezidents/programms/raket4.svg';

const Services = () => {
  return (
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <p className={styles.title}>служба заботы</p>

        <ul className={styles.list}>
          <li>
            <LISVG />
            техническая служба
          </li>
          <li>
            <LISVG />
            служба безопасности
          </li>
          <li>
            <LISVG />
            клининговая служба
          </li>
          <li>
            <LISVG />
            система навигации
          </li>
        </ul>
        <div className={styles.desc}>
          дополнительно:
          <ul className={styles.list2}>
            <li>УСЛУГИ ГРУЗЧИКОВ</li>
            <li>АРЕНДА МЕБЕЛИ И ОБОРУДОВАНИЯ</li>
            <li>СТРАХОВАНИЕ</li>
          </ul>
        </div>
        <Raket3 className={styles.bavkSvg} />
      </div>
      <div className={styles.rightColumn}>
        <p className={styles.title}>жизнь²</p>
        <ul className={styles.list}>
          <li>
            <LISVG />
            поддерживающие события для резидентов
          </li>
          <li>
            <LISVG />
            информационно- рекламная деятельность
          </li>
          <li>
            <LISVG />
            менеджмент событий
          </li>
          <li>
            <LISVG />
            работа с инициативами резидентов
          </li>
        </ul>
        <Raket4 className={styles.bavkSvg} />
      </div>
    </div>
  );
};
export default Services;
