import React from 'react';
import styles from './Burger.module.scss';
// import className from

const Burger = ({ modalActive, setModalActive, order }) => {
  return (
    <button
      className={
        modalActive ? `${styles.burger} ${styles.pressed}` : styles.burger
      }
      onClick={() =>
        modalActive ? setModalActive(false) : setModalActive(true)
      }
      style={!order ? { marginLeft: 'auto' } : null}
    >
      <span />
      <span />
      <span />
    </button>
  );
};

export default Burger;
