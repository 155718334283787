/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import styles from './ExhibitionsItem.module.scss';
import { useNavigate } from 'react-router-dom';

const ExhibitionsItem = ({ item, width, background }) => {
  const navigate = useNavigate();
  const itemClickHandler = () => {
    document.querySelector('#root').style.overflow = 'scroll';
    navigate('/gallery/' + item.id);
  };
  const formateDate = (date) => {
    const array = date.split('-');

    const monthes = [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ];

    return array[2] + ' ' + monthes[+array[1] - 1] + ' ' + array[0];
  };

  return (
    <div
      onClick={itemClickHandler}
      className={styles.item}
      style={{
        minWidth: '200px',
        width: width ? width : '250',
        backgroundColor: background ? background() : '',
      }}
    >
      <div className={styles.image}>
        <img src={item.image} />
      </div>

      <p className={styles.title}>{item.title}</p>
      <div className={styles.date}>
        {item.start_date && <span>{formateDate(item.start_date)}</span>}
        {item.finish_date && <span> - {formateDate(item.finish_date)}</span>}
      </div>

    </div>
  );
};

export default ExhibitionsItem;
