/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Container from '../../../templates/Container';
import PageTemplate from '../../../templates/PageTemplate';

import styles from './MainPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import MainPageContent from './MainPaigeComponents/MainPageContent';
import ColorWrapper from '../../../elements/ColorWrapper/ColorWrapper';
import MasonryBlock from '../../../elements/MasonryBlock/MasonryBlock';
import ExhibitionImage from '../../../elements/ExhibitionImage/ExhibitionImage';
import MobileMainPageContent from './MainPaigeComponents/MobileMainPageContent';
import useMobileDetect from 'use-mobile-detect-hook';
import { setActiveExhibitionIndexAction } from '../../../../redux/reducers/exhibitionReducer';
import { Link, useNavigate, useParams } from 'react-router-dom';

const MainPage = ({ orderDict, setBackLink }) => {
  const orderActive = true;

  const [exhibitions, setExhibitions] = useState(false);
  let [activeExhibition, _setActiveExhibition] = useState();

  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const params = useParams();

  useEffect(() => {
    if (!params || !exhibitions) return;
    if (!exhibitions.find((item) => item.id === +params.id)) return;
    _setActiveExhibition(+params.id);
  }, [params, exhibitions]);

  const dispatch = useDispatch();
  const data = useSelector((state) => state.exhibitions.exhibitions);
  const COLORS = ['#4F4B98', '#F0E400', '#2CA99B', '#D6358C'];
  const IMAGES_PER_PAGE = 12;

  let parsePicturesArray = (array, pagesQuantity, picturesPerPage) => {
    let arrayCopy = [...array];
    let rez = [];
    for (let i = 0; i < pagesQuantity; i++) {
      let page = [];
      for (let i = 0; i < picturesPerPage; i++) {
        page.push(arrayCopy.shift());
      }
      rez.push(page);
    }

    return rez;
  };

  let getNextColor = (pageIndex) => {
    return COLORS[pageIndex % COLORS.length];
  };
  const navigate = useNavigate();
  let pages = [];
  // let sliderText;
  if (exhibitions && activeExhibition) {
    let firstExhibition = exhibitions.find(
      (item) => item.id === +activeExhibition
    );
    // sliderText = firstExhibition.description;

    let exhibition_pictures = firstExhibition.pictures;
    let pagesQuantity = Math.ceil(exhibition_pictures.length / IMAGES_PER_PAGE);
    pages = parsePicturesArray(
      exhibition_pictures,
      pagesQuantity,
      IMAGES_PER_PAGE
    );
  }

  useEffect(() => {
    if (data.length > 0) {
      setExhibitions(data.filter((item) => !item.is_archived));
    }
    return () => {
      setBackLink('/gallery/');
    };
  }, [data]);

  const clickNextExhibitionsHandler = (currentId) => {
    const currentExhibitionIndex = exhibitions.findIndex(
      (item) => item.id === +currentId
    );
    if (exhibitions[currentExhibitionIndex + 1]) {
      const nextElemId = exhibitions[currentExhibitionIndex + 1].id;
      _setActiveExhibition(nextElemId);
      navigate(`/gallery/${nextElemId}`);
    } else {
      const firstElemId = exhibitions[0].id;
      _setActiveExhibition(firstElemId);
      navigate(`/gallery/${firstElemId}`);
    }
  };

  const clickPrevExhibitionsHandler = (currentId) => {
    const currentExhibitionIndex = exhibitions.findIndex(
      (item) => item.id === +currentId
    );
    if (currentExhibitionIndex > 0) {
      const prevElemId = exhibitions[currentExhibitionIndex - 1].id;
      _setActiveExhibition(prevElemId);
    } else {
      const lastElemId = exhibitions[exhibitions.length - 1].id;
      _setActiveExhibition(lastElemId);
    }
  };

  useEffect(() => {
    dispatch(setActiveExhibitionIndexAction(activeExhibition));
  }, [activeExhibition]);

  return (
    <PageTemplate
      order={orderActive}
      orderLink='/gallery/order'
      orderDict={orderDict}
    >
      <canvas id='noisy-canvas' className={styles.noisy_canvas}></canvas>
      <Container>
        {!activeExhibition && (
          <div className={styles.emptyText}>
            <h1
              style={{
                marginBottom: 20,
              }}
            >
              Ищем выставку...
            </h1>
            <div>
              Если это занимает много времени, вернитесь{' '}
              <Link to={'/gallery'}>к списку выставок </Link> и попробуйте
              поискать еще раз
            </div>
          </div>
        )}
      </Container>
      {exhibitions &&
        activeExhibition &&
        (isMobile ? (
          <MobileMainPageContent
            activeExhibition={activeExhibition}
            setActiveExhibition={_setActiveExhibition}
            exhibitionsCount={exhibitions.length}
            data={exhibitions.find((item) => item.id === +activeExhibition)}
            next={clickNextExhibitionsHandler}
            prev={clickPrevExhibitionsHandler}
          />
        ) : (
          <MainPageContent
            activeExhibition={activeExhibition}
            setActiveExhibition={_setActiveExhibition}
            exhibitionsCount={exhibitions.length}
            data={exhibitions.find((item) => item.id === +activeExhibition)}
            next={clickNextExhibitionsHandler}
          />
        ))}
      {/* </Container> */}

      {exhibitions && (
        <>
          {pages.map((page, index) => {
            return (
              <ColorWrapper
                color={getNextColor(index)}
                key={index}
                activeExhibition={activeExhibition}
              >
                <MasonryBlock>
                  {page.map(
                    (item, index) =>
                      item && (
                        <ExhibitionImage
                          key={index}
                          url={item.thumbnail}
                          author={item.author}
                          name={item.title}
                          id={item.id}
                        />
                      )
                  )}
                </MasonryBlock>
              </ColorWrapper>
            );
          })}
        </>
      )}
    </PageTemplate>
  );
};

export default MainPage;
