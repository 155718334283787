import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Residents.module.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSlider.scss';
import { ReactComponent as GoBackArrow } from '../../../assets/img/arrow-left.svg';
import { ReactComponent as MobileGoBackArrow } from '../../../assets/img/showroom/goBackMobile.svg';
// import classNames from 'classnames';
import { ReactComponent as Web } from '../../../assets/img/web.svg';
import { ReactComponent as Email } from '../../../assets/img/mail.svg';
import { ReactComponent as Tel } from '../../../assets/img/tel.svg';
import { getResident } from '../../../redux/requests/getResident';
import useMobileDetect from 'use-mobile-detect-hook';

const Residents = () => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();
  const { id } = useParams();
  const [resident, setResident] = useState();
  const navigate = useNavigate();

  const settings = {
    dots: false,
    dotsClass: 'dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    getResident(id).then((data) => setResident(data));
  }, []);

  return (
    <div className={styles.resident}>
      <div className={styles.ResWrapper}>
        <div
          onClick={() => navigate('/cluster#residentsList')}
          className={styles.backArrow}
        >
          {isMobile ? (
            <MobileGoBackArrow />
          ) : (
            <>
              <GoBackArrow />
              Назад
            </>
          )}
        </div>
        {resident && (
          <div className={styles.card}>
            <div className={styles.third}>
              <Slider {...settings}>
                {resident.gallery
                  .sort((a, b) => a.order_index - b.order_index)
                  .reverse()
                  .map((image, id) => (
                    <div key={id}>
                      <img className={styles.slider} src={image.image} />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className={styles.second}>
              <p className={styles.secondTitle}>{resident.title}</p>
              <p
                className={styles.cardDesc}
                dangerouslySetInnerHTML={{
                  __html: resident.description,
                }}
              ></p>
              <a
                target='_blank'
                href={
                  resident.social_media
                    ? resident.social_media
                    : `tel:${resident.phone}`
                }
                className={styles.buttonCard}
              >
                Приобрести продукцию
              </a>
            </div>
            <div className={styles.first}>
              <img className={styles.logo} src={resident.logo} />
              {resident.social_media && (
                <div className={styles.wrapper}>
                  <Web />
                  <a
                    target='_blank'
                    className={styles.link}
                    href={resident.social_media}
                  >
                    {resident.social_media}
                  </a>
                </div>
              )}
              {resident.email && (
                <div className={styles.wrapper}>
                  <Email />
                  <a className={styles.link} href={`mailto:${resident.email}`}>
                    {resident.email}
                  </a>
                </div>
              )}
              {resident.phone && (
                <div className={styles.wrapper}>
                  <Tel />
                  <a className={styles.phone} href={`tel:${resident.phone}`}>
                    {resident.phone}
                  </a>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Residents;
