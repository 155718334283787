import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';

import styles from './OrderPage.module.scss';
import MobileInputNumber from './MobileInputNumber';
import { MEDIA_URL } from '../../../redux/API/BASE_URL';
import { deleteItemFromShowroomOrderAction } from '../../../redux/reducers/showroomOrderReducer';
import { useDispatch } from 'react-redux';
import { deleteItemFromOrderAction } from '../../../redux/reducers/orderReducer';
import { useLocation } from 'react-router-dom';

const MobileOrderItem = ({
  product,
  value,
  onClose,
  onChange,
  text,
  isGallery,
}) => {
  const [localValue, setLocalValue] = useState(product.price);
  const onChangeCounter = (value) => {
    setLocalValue(value);
    onChange(product, value);
  };
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const onCloseHandler = () => {
    onClose(product);
  };
  return (
    <div className={styles.mobile_order_item}>
      <div className={styles.img_wrapper}>
        <img
          src={
            product.thumbnail
              ? MEDIA_URL + product.thumbnail
              : product.cropped_image
          }
          alt={product.name}
        />
      </div>
      <div className={styles.order_info}>
        <div className={styles.order_name}>
          <span className={styles.head_span}>
            {product.name ? product.name : product.title}
          </span>
        </div>
        <div className={styles.other_info}>
          {isGallery && (
            <div>
              <span className={styles.autor}>
                <span className={styles.head_span}>Автор:</span>{' '}
                <span>{product.author}</span>
              </span>
            </div>
          )}

          <div>
            <span className={styles.head_span}>
              {isGallery ? 'Характеристики' : 'Магазин'}:{' '}
            </span>
            <span>{text}</span>
          </div>
        </div>
      </div>
      <div className={styles.price_info}>
        <span>
          {String(product.count * product.price).replace(
            /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
            '$1 '
          )}{' '}
          ₽
        </span>
        <MobileInputNumber
          defaultValue={value}
          quantity={product.quantity}
          funcChange={onChangeCounter}
          product={product}
        />
      </div>
      <div className={styles.close}>
        <CloseIcon
          onClick={() => {
            if (pathname.includes('showroom')) {
              dispatch(deleteItemFromShowroomOrderAction(product.id));
            } else {
              dispatch(deleteItemFromOrderAction(product.id));
            }
          }}
        />
      </div>
    </div>
  );
};

export default MobileOrderItem;
