import classNames from 'classnames';

import styles from './ClusterTabs.module.scss';
import Rezidentstvo from './Rezidentstvo/Rezidentstvo';
import { useRef, useState, useEffect } from 'react';
import Programms from './Programms/Programms';
import Services from './Services/Services';
import Events from './Events/Events';
import Konkurs from './Konkurs/Konkurs';
import Class from './Class/Class';

const ClusterTabs = () => {
  const [tab, setTab] = useState(1);
  const titleRef = useRef();
  useEffect(() => {
    titleRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  }, [tab]);

  return (
    <section className={styles.clusterTabs}>
      <div ref={titleRef} className={styles.titleWrapper}>
        <p className={styles.title1}>
          {tab == 1
            ? '/ РЕЗИДЕНСТВО'
            : tab == 2
            ? '/ Классификация'
            : tab == 3
            ? '/ Программы'
            : tab == 4
            ? '/ Сервисы'
            : tab == 1
            ? '/ Мероприятия'
            : '/ Критерии Конкурсного Отбора'}
        </p>
        <p className={styles.title2}>Политика по работе с резидентами</p>
      </div>
      {tab == 1 && <Rezidentstvo />}
      {tab == 2 && <Class />}
      {tab == 3 && <Programms />}
      {tab == 4 && <Services />}
      {tab == 5 && <Events />}
      {tab == 6 && <Konkurs />}
      <div className={styles.tabs}>
        <p
          className={classNames(styles.tab, { [styles.tabActive]: tab == 1 })}
          onClick={() => setTab(1)}
        >
          Резиденство
        </p>
        <p
          className={classNames(styles.tab, { [styles.tabActive]: tab == 2 })}
          onClick={() => setTab(2)}
        >
          Классификация
        </p>
        <p
          className={classNames(styles.tab, { [styles.tabActive]: tab == 3 })}
          onClick={() => setTab(3)}
        >
          Программы
        </p>
        <p
          className={classNames(styles.tab, { [styles.tabActive]: tab == 4 })}
          onClick={() => setTab(4)}
        >
          Сервисы
        </p>
        <p
          className={classNames(styles.tab, { [styles.tabActive]: tab == 5 })}
          onClick={() => setTab(5)}
        >
          Мероприятия
        </p>
        <p
          className={classNames(styles.tab, { [styles.tabActive]: tab == 6 })}
          onClick={() => setTab(6)}
        >
          Критерии конкурсного отбора
        </p>
      </div>
    </section>
  );
};
export default ClusterTabs;
