import React from "react";
import { useLocation, useParams } from "react-router-dom";

const VkAuth = () => {
  const location = useLocation();

  const hashString = location.search;
  const tokenStringEndIndex = hashString.indexOf("&");
  const tokenStringStartIndex = hashString.indexOf("=");

  const emailStartIndex = hashString.indexOf("&email=");
  const emailEndIndex = hashString.indexOf("&", emailStartIndex + 1);

  const emailString = hashString.substring(emailStartIndex + 7, emailEndIndex);

  const tokenString = hashString.substring(
    tokenStringStartIndex + 1,
    tokenStringEndIndex
  );

  window.location.href =
    // "exp://192.168.1.52:19000/--/screens/auth-vk?=token=" +
    "industry-app://screens/auth-vk?=token=" +
    tokenString
    // "&email=" +
    // emailString.toLowerCase();

  return;
};

export default VkAuth;
