import styles from './Programms.module.scss';
import { ReactComponent as LISVG } from '../../../../../../assets/img/cluster/rezidents/programms/li.svg';
import { ReactComponent as Raket } from '../../../../../../assets/img/cluster/rezidents/programms/raket.svg';
import { ReactComponent as Raket2 } from '../../../../../../assets/img/cluster/rezidents/programms/raket2.svg';

const Programms = () => {
  return (
    <div className={styles.content}>
      <div className={styles.leftColumn}>
        <p className={styles.title}>ПРОЕКТЫ на НАЧаЛЬНоЙ стадии реализации</p>
        <p className={styles.desc}>
          ФОРМИРОВАНИЕ КОМАНДЫ, РАЗРАБОТКА И ТЕСТИРОВАНИЕ БИЗНЕС-МОДЕЛИ,
          СОЗДАНИЕ ПРОТОТИПА, КОММЕРЦИАЛИЗАЦИЯ ПРОЕКТА
        </p>
        <ul className={styles.list}>
          <li>
            <LISVG />
            БИЗНЕС-СТАРТ
          </li>
          <li>
            <LISVG />
            аудит и тРекинг
          </li>
        </ul>
        <Raket className={styles.bavkSvg} />
      </div>
      <div className={styles.rightColumn}>
        <p className={styles.title}>ПРОЕКТЫ в стадии активного роста,</p>
        <p className={styles.desc}>развития и масштабирования</p>
        <ul className={styles.list}>
          <li>
            <LISVG />
            потенциал развития
          </li>
          <li>
            <LISVG />
            аудит
          </li>
        </ul>
        <Raket2 className={styles.bavkSvg} />
      </div>
    </div>
  );
};
export default Programms;
