const initialState = {
  showroomOrderList: JSON.parse(
    window.localStorage.getItem("place_showroom_orders") || "[]"
  ),
};

const SET_ITEM_SHOWROOM = "SET_ITEM_SHOWROOM";
const DELETE_ITEM_SHOWROOM = "DELETE_ITEM_SHOWROOM";
const INCREMENT_SHOWROOM = "INCREMENT_SHOWROOM";
const DECREMENT_SHOWROOM = "DECREMENT_SHOWROOM";

export const showroomOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEM_SHOWROOM:
      if (
        state.showroomOrderList.some((item) => item.id === action.payload.id)
      ) {
        return {
          ...state,
          showroomOrderList: state.showroomOrderList.map((item) => {
            if (item.id === action.payload.id) {
              item.count = item.count + 1;
              return item;
            } else return item;
          }),
        };
      } else {
        return {
          ...state,
          showroomOrderList: [...state.showroomOrderList, action.payload],
        };
      }
    case DELETE_ITEM_SHOWROOM:
      if (state.showroomOrderList.length > 1) {
        return {
          ...state,
          showroomOrderList: state.showroomOrderList.filter(
            (item) => item.id !== action.payload
          ),
        };
      } else if (state.showroomOrderList.length === 1) {
        return { ...state, showroomOrderList: [] };
      }
    // eslint-disable-next-line no-fallthrough
    case INCREMENT_SHOWROOM:
      return {
        ...state,
        showroomOrderList: state.showroomOrderList.map((item) => {
          if (item.id === action.payload.id) {
            item.count = item.count + 1;
            return item;
          } else return item;
        }),
      };
    case DECREMENT_SHOWROOM:
      return {
        ...state,
        showroomOrderList: state.showroomOrderList.map((item) => {
          if (item.id === action.payload.id) {
            item.count = item.count - 1;
          }
          return item;
        }),
      };
    default:
      return state;
  }
};

export const setNewItemToShowroomOrderAction = (payload) => ({
  type: SET_ITEM_SHOWROOM,
  payload,
});

export const deleteItemFromShowroomOrderAction = (payload) => ({
  type: DELETE_ITEM_SHOWROOM,
  payload,
});

export const incrementShowroomAction = (payload) => ({
  type: INCREMENT_SHOWROOM,
  payload,
});
export const decrementShowroomAction = (payload) => ({
  type: DECREMENT_SHOWROOM,
  payload,
});
