import { useRef, useEffect } from 'react';
import Vector from '../../../../../assets/img/cluster/Vector.png';
import Vector2 from '../../../../../assets/img/cluster/Vector2.png';
import Vector3 from '../../../../../assets/img/cluster/Vector3.png';

import styles from './ClusterTitle.module.scss';
import { useState } from 'react';
import useWindowWidth from '../../../../../hooks/useWindowWidth';
import { useNavigate } from 'react-router-dom';

const ClusterTitle = () => {
  const width = useWindowWidth();
  const navigate = useNavigate();

  return (
    <section id='cluster' className={styles.cluster}>
      <div className={styles.container}>
        <h1 className={styles.header}>
          <span>КВАД</span>
          <span>РАТ</span>
        </h1>
        <div className={styles.about}>
          <p className={styles.subHeader}>
            / креативный <br />
            <span> кластер</span>
          </p>
          <p className={styles.aboutText}>
            Опорная точка инфраструктуры развития креативного
            предпринимательства
            <br />в Красноярском крае
          </p>
        </div>
        <div className={styles.buttons}>
          <div className={styles.button1Wrapper}>
            <script data-b24-form='click/17/1oqx96' data-skip-moving='true'>
              {(function (w, d, u) {
                var s = d.createElement('script');
                s.async = true;
                s.src = u + '?' + ((Date.now() / 180000) | 0);
                var h = d.getElementsByTagName('script')[0];
                h.parentNode.insertBefore(s, h);
              })(
                window,
                document,
                'https://cdn-ru.bitrix24.ru/b24021132/crm/form/loader_17.js'
              )}
            </script>
            <button type='button'>/ Стать резидентом</button>
            {/* <div className={styles.dropDown1}>
              {!isSend ? (
                <>
                  <input placeholder='Имя*' />
                  <input placeholder='Телефон*' />
                  <input placeholder='Почта*' />
                  <button
                    onClick={() => setIsSend(true)}
                    className={styles.sendButton}
                  >
                    Отправить
                  </button>
                  <p>*Все поля обязательны для заполнения</p>
                </>
              ) : (
                <div className={styles.submitMessage}>
                  <p>
                    Спасибо за обращение! Менеджер свяжется с вами в ближайшее
                    время закрыть х Отправить
                  </p>
                  <button
                    onClick={(e) => {
                      setIsSend(false);
                      e.target.parentElement.parentElement.style.height =
                        0 + 'px';
                    }}
                    className={styles.close}
                  >
                    закрыть х
                  </button>
                </div>
              )}
            </div> */}
          </div>
          <div className={styles.button2Wrapper}>
            <script data-b24-form='click/17/1oqx96' data-skip-moving='true'>
              {(function (w, d, u) {
                var s = d.createElement('script');
                s.async = true;
                s.src = u + '?' + ((Date.now() / 180000) | 0);
                var h = d.getElementsByTagName('script')[0];
                h.parentNode.insertBefore(s, h);
              })(
                window,
                document,
                'https://cdn-ru.bitrix24.ru/b24021132/crm/form/loader_17.js'
              )}
            </script>
            <button className={styles.whiteButton} type='button'>
              / Получить консультацию
            </button>
            {/* <div className={styles.dropDown2}>
              {!isSend ? (
                <>
                  <input placeholder='Имя*' />
                  <input placeholder='Телефон*' />
                  <input placeholder='Почта*' />
                  <input placeholder='Что хотите узнать?*' />
                  <button
                    onClick={() => setIsSend(true)}
                    className={styles.sendButton}
                  >
                    Отправить
                  </button>
                  <p>*Все поля обязательны для заполнения</p>
                </>
              ) : (
                <div className={styles.submitMessage}>
                  <p>
                    Спасибо за обращение! Менеджер свяжется с вами в ближайшее
                    время закрыть х Отправить
                  </p>
                  <button
                    onClick={(e) => {
                      setIsSend(false);
                      e.target.parentElement.parentElement.style.height =
                        0 + 'px';
                    }}
                    className={styles.close}
                  >
                    закрыть х
                  </button>
                </div>
              )}
            </div> */}
          </div>
          <div className={styles.button3Wrapper}>
            <script data-b24-form='click/17/1oqx96' data-skip-moving='true'>
              {(function (w, d, u) {
                var s = d.createElement('script');
                s.async = true;
                s.src = u + '?' + ((Date.now() / 180000) | 0);
                var h = d.getElementsByTagName('script')[0];
                h.parentNode.insertBefore(s, h);
              })(
                window,
                document,
                'https://cdn-ru.bitrix24.ru/b24021132/crm/form/loader_17.js'
              )}
            </script>
            <button className={styles.purpleButton} type='button'>
              / Подобрать помещение
            </button>
            {/* <div className={styles.dropDown3}>
              {!isSend ? (
                <>
                  <input placeholder='Имя*' />
                  <input placeholder='Телефон*' />
                  <input placeholder='Почта*' />
                  <button
                    onClick={() => setIsSend(true)}
                    className={styles.sendButton}
                  >
                    Отправить
                  </button>
                  <p>*Все поля обязательны для заполнения</p>
                </>
              ) : (
                <div className={styles.submitMessage}>
                  <p>
                    Спасибо за обращение! Менеджер свяжется с вами в ближайшее
                    время закрыть х Отправить
                  </p>
                  <button
                    onClick={(e) => {
                      setIsSend(false);
                      e.target.parentElement.parentElement.style.height =
                        0 + 'px';
                    }}
                    className={styles.close}
                  >
                    закрыть х
                  </button>
                </div>
              )}
            </div> */}
          </div>
        </div>
      </div>
      {width > 1050 && (
        <div className={styles.containerImages}>
          <div className={styles.images}>
            <div className={styles.image}>
              <p>/ Мероприятия / новости</p>
              <div className={styles.firstWrapper}>
                <img src={Vector} />
                <div
                  onClick={() => navigate('/cluster#events')}
                  className={styles.firstWrapperInner}
                ></div>
              </div>
            </div>
            <div className={styles.image}>
              <p>/ Помещения в аренду</p>
              <div className={styles.firstWrapper}>
                <img src={Vector2} />
                <div
                  onClick={() => navigate('/cluster#arenda')}
                  className={styles.firstWrapperInner}
                ></div>
              </div>
            </div>
            <div className={styles.image}>
              <div className={styles.firstWrapper}>
                <img src={Vector3} />
                <div
                  onClick={() => navigate('/cluster#residents')}
                  className={styles.thirdWrapperInner}
                ></div>
              </div>
              <p className={styles.thirdTitleImage}>/ Резиденты</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
export default ClusterTitle;
