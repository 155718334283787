import React, { useEffect, useState } from 'react';
import QRcode from '../../../assets/img/QRcode.png';
import styles from './Auth.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../../redux/requests/auth';
import { couponUsage } from '../../../redux/requests/couponUsage';

const Auth = () => {
  const navigate = useNavigate();
  const [result, setResult] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [text, setText] = useState('');
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const hashString = location.search;
  const partnerStringEndIndex = hashString.indexOf('&');
  const partnerStringStartIndex = hashString.indexOf('=');
  const partnerString = hashString.substring(
    partnerStringStartIndex + 1,
    partnerStringEndIndex
  );

  const imageStartIndex = hashString.indexOf('&partnerImage=');
  const imageEndIndex = hashString.indexOf('&idCoupon=');
  const imageString = hashString.substring(imageStartIndex + 14, imageEndIndex);

  const userIdStartIndex = hashString.indexOf('&id=');
  const userId = hashString.substring(userIdStartIndex + 4);

  const couponIdStartIndex = hashString.indexOf('&idCoupon=');
  const couponIdEndIndex = hashString.indexOf('&id=');
  const couponId = hashString.substring(
    couponIdStartIndex + 10,
    couponIdEndIndex
  );

  useEffect(() => {
    let token = '';
    if (window.localStorage.getItem('token')) {
      token = window.localStorage.getItem('token');
    }
    if (window.sessionStorage.getItem('token')) {
      token = window.sessionStorage.getItem('token');
    }
    if (hashString) {
      couponUsage(token, userId, couponId).then((res) => {
        setText(res);
        setChecked(false);
        setEmail('');
        setPassword('');
        setResult(!result);
      });
    }
  }, []);

  const handleClick = async () => {
    if (!email || !password) {
      setText('Логин и пароль должны быть заполнены!');
    } else {
      const data = await auth(
        { email, password },
        checked,
        userId,
        couponId,
        hashString
      );
      if (hashString) {
        setText(
          typeof data === 'string' ? data : data.non_field_errors.join(' ')
        );
        setChecked(false);
        setEmail('');
        setPassword('');
      } else {
        if (data?.non_field_errors.length > 0) {
          setText(
            typeof data === 'string' ? data : data.non_field_errors.join(' ')
          );
          setChecked(false);
          setEmail('');
          setPassword('');
        } else navigate('/coupons-usage');
      }
    }
    setResult(!result);
  };

  return (
    <div className={styles.page}>
      <div className={styles.wrapper}>
        <figure className={styles.img}>
          <img src={imageString} width='75px' />
          <figcaption className={styles.imgCaption}>
            {decodeURIComponent(partnerString)}
          </figcaption>
        </figure>
        <p className={styles.subTitle}>
          Чтобы подтвердить оказанные услуги, пожалуйста, введите логин и пароль
        </p>
        {result ? (
          <>
            <div className={styles.box}>
              <p className={styles.text}>{text}</p>
            </div>
          </>
        ) : (
          <>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={styles.input}
              placeholder='email'
              type='email'
            />
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className={styles.input}
              placeholder='пароль'
              type='password'
            />
            <label className={styles.remember}>
              <input
                checked={checked}
                onChange={() => setChecked(!checked)}
                className={styles.checkbox}
                type='checkbox'
              />
              Запомнить меня
              <span className={styles.customCheckbox}></span>
            </label>
          </>
        )}

        <button onClick={handleClick} className={styles.button}>
          {result ? 'Назад' : 'Подтвердить'}
        </button>
      </div>
    </div>
  );
};

export default Auth;
