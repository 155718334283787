import React, { useRef, useState } from 'react';
import styles from './MasterSection.module.scss';
import Container from '../../../templates/Container';
import useMobileDetect from 'use-mobile-detect-hook';

import { Link } from 'react-router-dom';

import { ReactComponent as IndustryIcon } from '../../../../assets/img/master-logo.svg';

import button_1 from '../../../../assets/img/test_pictures/button_1.jpg';
import button_2 from '../../../../assets/img/test_pictures/button_2.jpg';
import button_3 from '../../../../assets/img/test_pictures/button_3.jpg';


const MasterSection = () => {
  const master = useRef();

  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const [activeButton, setActiveButton] = useState(0);
  const indexSection = [0, 1, 2, 3];

  const setActiveSection = function(evt, idButton) {
    // если клик по активной вкладке
    // let section = evt.target.closest('.' + styles.section);
    if (activeButton === idButton) {
      setTimeout(()=>{
        document.querySelectorAll('.' + styles.section__previous).forEach(sec=>{
          sec.style.zIndex = null
        })
        // section.style.zIndex = null
        setActiveButton(0);
      },10)

      return
    }
    setTimeout(()=>{
      document.querySelectorAll('.' + styles.section__previous).forEach(sec=>{
        let secId = +sec.id.split('-')[1]
        if (secId > 0) {
          sec.style.zIndex = (Math.max(...indexSection) - secId) * 100;
        }

      })
    },10)

    // если клик по неактивной вкладке
    setActiveButton(idButton);
  }

  return (
    <div className={isMobile ? `${styles.wrapper} ${styles.wrapper__mobile}` : styles.wrapper}>

      {isMobile ?
        <div className={styles.control}>

          <button
            className={activeButton === indexSection[1] ? `${styles.button} ${styles.button__pressed}` : styles.button}
            type='button'
            id="button_1"
            data-index={indexSection[1]}
            onClick={(evt) => {setActiveSection(evt, indexSection[1])}}>

            <img src={button_3} alt="" />
            <span>Галерея</span>
          </button>

          <button
          className={activeButton === indexSection[2] ? `${styles.button} ${styles.button__pressed}` : styles.button}
          type='button'
          id="button_2"
          data-index={indexSection[2]}
          onClick={(evt) => {setActiveSection(evt, indexSection[2])}}>
            <img src={button_1} alt="" />
            <span>Онлайн-шоурум</span>
          </button>

          <button className={activeButton === indexSection[3] ? `${styles.button} ${styles.button__pressed}` : styles.button}
          type='button'
          id="button_3"
          data-index={indexSection[3]}
          onClick={(evt) => {setActiveSection(evt, indexSection[3])}}>
            <img src={button_2} alt="" />
            <span>Оффлайн-шоурум</span>
          </button>
        </div> : null }



      <div className={isMobile ? `${styles.master} ${styles.master__mobile}` : styles.master } ref={master}>

        <div
          className={
            styles.section + ' ' +
            (isMobile ? styles.section__mobile : '') + ' ' +
            ((activeButton === indexSection[0]) ? styles.section__active : '') + ' ' +
            ((indexSection[0] < activeButton) ? styles.section__previous : '')
          }

          id="sec-0" data-index={indexSection[0]} onClick={(evt) => {setActiveSection(evt, indexSection[0])}}>

          <Container>
            <div className={isMobile ? `${styles.section_info} ${styles.section_info__mobile}` : styles.section_info}>
              <div>
                <IndustryIcon />
                {/* <h3>
                  <span>place</span><br />
                  <span>industry art</span>
                </h3> */}
              </div>

              <p>
                {/* <span>Креативное пространство «Индустрия»</span><br /> */}
                <span>Креативное пространство, которое объединяет культурные события города, кофейню, галерею современного искусства и шоурум с&nbsp;изделиями красноярских производителей в одном месте.</span></p>
            </div>
          </Container>
        </div>


        <div
        className={
          styles.section + ' ' +
          (isMobile ? styles.section__mobile : '') + ' ' +
          ((activeButton === indexSection[1]) ? styles.section__active : '') + ' ' +
          ((indexSection[1] < activeButton) ? styles.section__previous : '')
        } id="sec-1" data-index={indexSection[1]} onClick={(evt) => {setActiveSection(evt, indexSection[1])}}>

          <Container>
            <div className={isMobile ? `${styles.section_info} ${styles.section_info__mobile}` : styles.section_info}>
              <h3>
                <span>Галерея</span>
              </h3>
              <p>Выставочное пространство, где мы показываем современное искусство и другие кретивные направления. Экспозиции обновляются раз в&nbsp;месяц. Работы доступны к приобретению.</p>
              <Link to="/gallery">Перейти</Link>
            </div>
          </Container>
        </div>


        <div
          className={
            styles.section + ' ' +
            (isMobile ? styles.section__mobile : '') + ' ' +
            ((activeButton === indexSection[2]) ? styles.section__active : '') + ' ' +
            ((indexSection[2] < activeButton) ? styles.section__previous : '')
          } id="sec-2" data-index={indexSection[2]} onClick={(evt) => {setActiveSection(evt, indexSection[2])}}>

          <Container>
            <div className={isMobile ? `${styles.section_info} ${styles.section_info__mobile}` : styles.section_info}>

              <h3>
                <span>Онлайн шоурум</span>
              </h3>
              <p>Здесь представлены товары, созданные мастерами Красноярского края. Знакомьтесь с&nbsp;брендами и приобретайте онлайн.</p>
              <Link to="/showroom">Перейти</Link>
            </div>
          </Container>
        </div>


        <div
          className={
            styles.section + ' ' +
            (isMobile ? styles.section__mobile : '') + ' ' +
            ((activeButton === indexSection[3]) ? styles.section__active : '') + ' ' +
            ((indexSection[3] < activeButton) ? styles.section__previous : '')
          } id="sec-3" data-index={indexSection[3]} onClick={(evt) => {setActiveSection(evt, indexSection[3])}}>

          <Container>
            <div className={isMobile ? `${styles.section_info} ${styles.section_info__mobile}` : styles.section_info}>

              <h3>
                <span>Оффлайн шоурум</span>
              </h3>
              <p>Пространство, где можно найти эксклюзивные вещи красноярских производителей, выбрать особенный подарок или порадовать себя сибирскими брендами высокого уровня.</p>
            </div>
          </Container>
        </div>
      </div>

    </div>
  )
}

export default MasterSection;