import React from 'react';
import Footer from '../elements/Footer/Footer';
// import Container from './Container';
import styles from './PageTemplate.module.scss';
import useMobileDetect from 'use-mobile-detect-hook';
import MobileFooter from '../elements/Footer/MobileFooter';

const PageTemplate = ({ children, isFooter = true }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  return (
    <div className={styles.PageTemplate}>
      <main id='page' style={{ flex: '1 1 auto' }}>
        {children}
      </main>

      {isFooter ? isMobile ? <MobileFooter /> : <Footer /> : null}
    </div>
  );
};

export default PageTemplate;
