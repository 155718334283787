import { CLUSTER_API } from '../API/BASE_URL';

export const getBookingCalendar = async (id) => {
  const response = await fetch(CLUSTER_API + `room/booking-calendar/?room=${id}`, {
    method: 'GET',
  });

  const data = await response.json();
  return data;
};
