import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/img/close.svg';
import InputNumber from './InputNumber';

import styles from './OrderPage.module.scss';
import { MEDIA_URL } from '../../../redux/API/BASE_URL';
import { useDispatch } from 'react-redux';
import { deleteItemFromOrderAction } from '../../../redux/reducers/orderReducer';
import { useLocation } from 'react-router-dom';
import { deleteItemFromShowroomOrderAction } from '../../../redux/reducers/showroomOrderReducer';

const OrderItem = ({ product, value, onChange, onClose }) => {
  const [localValue, setLocalValue] = useState(product.quantity);
  const onChangeCounter = (value) => {
    // setLocalValue(value);
    // onChange(product, value);
  };

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const onCloseHandler = () => {
    if (pathname.includes('showroom')) {
      dispatch(deleteItemFromShowroomOrderAction(product.id));
    } else {
      dispatch(deleteItemFromOrderAction(product.id));
    }
  };
  return (
    <div className={styles.order_item}>
      <div className={styles.img_wrapper}>
        <img src={MEDIA_URL + product.thumbnail} alt={product.title} />
      </div>
      <div className={styles.order_info}>
        <div className={styles.order_name}>
          <span className={styles.head_span}>{product.title}</span>
          <span>{product.author}</span>
        </div>
        <div className={styles.other_info}>
          <div>
            <span className={styles.head_span}>Материал</span>
            <span>{product.material ? product.material : 'Не указано'}</span>
          </div>
          <div>
            <span className={styles.head_span}>Техника</span>
            <span>{product.technique ? product.technique : 'Не указано'}</span>
          </div>
          <div>
            <span className={styles.head_span}>Размер</span>
            <span>{product.size ? product.size : 'Не указано'}</span>
          </div>
        </div>
      </div>
      <div className={styles.price_info}>
        <div className={styles.wrap}>
          <span>
            {String(product.count * product.price).replace(
              /(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g,
              '$1 '
            )}{' '}
            ₽
          </span>
          <InputNumber
            defaultValue={value}
            quantity={product.quantity}
            product={product}
            funcChange={onChangeCounter}
          />
        </div>
        <CloseIcon onClick={onCloseHandler} />
      </div>
    </div>
  );
};

export default OrderItem;
