import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Container from '../../../templates/Container';
import PageTemplate from '../../../templates/PageTemplate';
import { ReactComponent as GoBackArrow } from '../../../../assets/img/arrow-left.svg';
import { ReactComponent as MobileGoBackArrow } from '../../../../assets/img/mobile-left-arrow.svg';

import styles from './ProfilePage.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setNewItemToOrderAction } from '../../../../redux/reducers/orderReducer';
import { BASE_URL, MEDIA_URL } from '../../../../redux/API/BASE_URL';

import useMobileDetect from 'use-mobile-detect-hook';
import ProfileSlider from './Slider/ProfileSlider';

// import { Link, useLocation, useParams } from 'react-router-dom';
// import { getPainterProfile } from '../../../../redux/requests/getPainterProfile';

const ProfilePage = ({ addProduct, orderDict, backLink }) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const [currentProfile, setCurrentProfile] = useState();
  const [currentOrder, setCurrentOrder] = useState();
  const [quantity, setQuantity] = useState(true);
  const [canIBuy, setCanIBay] = useState(true);
  const [descriptions, setDescriptions] = useState([]);
  const params = useParams();
  const dispatch = useDispatch();
  const exhibitions = useSelector((state) => state.exhibitions.exhibitions);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let item = { pictures: [] };
  exhibitions.forEach((exhibition) => {
    item.pictures.unshift(...exhibition.pictures);
  });
  const orderList = useSelector((state) => state.order.orderList);

  useEffect(() => {
    if (!item) return;
    setCurrentProfile(item.pictures.find((point) => point.id === +params.id));
  }, [item, params.id]);

  useEffect(() => {
    setCurrentOrder(orderList.find((point) => point.id === +params.id));
  }, [orderList, params.id]);

  const addToOrder = () => {
    if (currentProfile.count) {
      return;
      // addProduct(currentProfile)
    } else {
      addProduct({ ...currentProfile, count: 1 });
    }
  };

  useEffect(() => {
    if (currentProfile && currentProfile.description) {
      const arr = currentProfile.description.split('\n');
      setDescriptions(arr);
    }
  }, [currentProfile]);

  useEffect(() => {
    if (currentOrder && currentOrder.count >= quantity) {
      setCanIBay(false);
      return;
    }
  }, [currentOrder, quantity]);

  useEffect(() => {
    if (!orderList || !currentProfile) return;
    if (orderList.count === currentProfile.quantity) setQuantity(false);
  }, [currentProfile, orderList]);
  return (
    <PageTemplate orderLink='/gallery/order' order={true} orderDict={orderDict}>
      <Container>
        {currentProfile ? (
          <div>
            <div
              className={isMobile ? styles.mobile_main_info : styles.main_info}
            >
              {!isMobile && (
                <div className={styles.backLink}>
                  <Link to={-1}>
                    <>
                      <GoBackArrow /> Назад
                    </>
                  </Link>
                </div>
              )}
              {isMobile && (
                <div className={styles.firstString}>
                  <div className={styles.profileTitle}>
                    <div>
                      <span className={styles.title}>
                        {currentProfile.title}
                      </span>
                    </div>
                  </div>
                  <Link className={styles.backLink} to={-1}>
                    <MobileGoBackArrow />
                  </Link>
                </div>
              )}

              <div className={styles.grid_container}>
                <div className={styles.profileImg}>
                  {currentProfile.additional_photos.length > 0 ? (
                    <ProfileSlider
                      additional_photos={currentProfile.additional_photos}
                      main_photo={currentProfile.thumbnail}
                    />
                  ) : (
                    <div className={`${styles.slide} ${styles.slide_single}`}>
                      <img
                        src={MEDIA_URL + currentProfile.thumbnail}
                        alt={currentProfile.title}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.profileInfo}>
                  {!isMobile && (
                    <div className={styles.profileTitle}>
                      <div>
                        <span className={styles.title}>
                          {currentProfile.title}
                        </span>
                      </div>
                      {/* <Link to={backLink}>
                  {isMobile ? (
                    <MobileGoBackArrow />
                  ) : (
                    <>
                      <GoBackArrow /> Назад
                    </>
                  )}
                </Link> */}
                    </div>
                  )}
                  <dl>
                    {currentProfile.author && (
                      <div>
                        <dt className={styles.info_title}>автор:</dt>
                        <dd className={styles.info_param}>
                          <a
                            href={'/painters/' + currentProfile.author_id}
                            className={styles.author}
                          >
                            {currentProfile.author}
                          </a>
                        </dd>
                      </div>
                    )}
                    {currentProfile.technique && (
                      <div>
                        <dt className={styles.info_title}>медиум:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.technique
                            ? currentProfile.technique
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.material && (
                      <div>
                        <dt className={styles.info_title}>материал:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.material
                            ? currentProfile.material
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.size && (
                      <div>
                        <dt className={styles.info_title}>размер:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.size
                            ? currentProfile.size
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.creation_year && (
                      <div>
                        <dt className={styles.info_title}>год создания:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.creation_year
                            ? currentProfile.creation_year
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.object_type && (
                      <div>
                        <dt className={styles.info_title}>тип объекта:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.object_type
                            ? currentProfile.object_type
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.decor && (
                      <div>
                        <dt className={styles.info_title}>оформление:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.decor
                            ? currentProfile.decor
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.circulation && (
                      <div>
                        <dt className={styles.info_title}>тираж:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.circulation
                            ? currentProfile.circulation
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                    {currentProfile.printing_method && (
                      <div>
                        <dt className={styles.info_title}>способ печати:</dt>
                        <dd className={styles.info_param}>
                          {currentProfile.printing_method
                            ? currentProfile.printing_method
                            : 'Не указано'}
                        </dd>
                      </div>
                    )}
                  </dl>

                  <div className={styles.buy_block}>
                    <span className={styles.price}>
                      {currentProfile.price
                        .slice(0, -3)
                        .replace(/(\d{1,3}(?=(?:\d\d\d)+(?!\d)))/g, '$1 ')}{' '}
                      ₽
                    </span>
                    <button
                      className={
                        currentProfile.is_bought ? styles.saled : undefined
                      }
                      style={{
                        paddingRight: currentProfile.quantity < 1 ? 5 : '',
                        paddingLeft: currentProfile.quantity < 1 ? 5 : '',
                      }}
                      onClick={
                        currentProfile.quantity < 1 ||
                        orderList.some(
                          (point) => point.id === currentProfile.id
                        )
                          ? null
                          : addToOrder
                      }
                      disabled={
                        currentProfile.quantity < 1 ||
                        orderList.some(
                          (point) => point.id === currentProfile.id
                        )
                      }
                    >
                      {currentProfile.quantity < 1
                        ? 'В частной коллекции'
                        : orderList.some(
                            (point) => point.id === currentProfile.id
                          )
                        ? 'В корзине'
                        : 'Купить'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {currentProfile.description !== '' && (
              <div className={styles.description}>
                <p>Описание</p>
                <div className={styles.description_text}>
                  {descriptions &&
                    descriptions.map((item, index) => (
                      <p key={index}>{item}</p>
                    ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          <p>Упс, такой работы нет на сайте</p>
        )}
      </Container>
    </PageTemplate>
  );
};

export default ProfilePage;
