import classNames from 'classnames';
import styles from './ClusterRoadmap.module.scss';
import { useEffect, useRef } from 'react';
import { getAudio } from '../../../../../redux/requests/getAudio';
import { useState } from 'react';
import AudioPlayer from '../../../../elements/Audio/AudioPlayer/AudioPlayer';
import { useDraggable } from 'react-use-draggable-scroll';

const ClusterRoadmap = () => {
  const [audio, setAudio] = useState([]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [play, setPlay] = useState(false);
  const currentSong = audio[currentSongIndex];
  const list = useRef();
  const audioRef = useRef();
  const audioPlayerRef = useRef();
  const aboutRef = useRef();
  const historyRef = useRef();
  const { events } = useDraggable(historyRef);

  useEffect(() => {
    getAudio().then((data) =>
      setAudio(data.sort((a, b) => a.order_index - b.order_index))
    );
  }, []);

  console.log(audio);
  return (
    <section ref={aboutRef} className={styles.clusterRoadmap}>
      <div className={styles.header}>
        <p className={styles.about}>О кластере</p>
        <div className={styles.box}>
          <div className={styles.title}>
            <p>Креативный кластер</p>
            <p className={styles.titleDecoration}>Квадрат</p>
          </div>
          <p className={styles.description}>
            Главный инфраструктурный проект. Он располагается
            <br /> в центре Красноярска на площадях бывшего завода «Квант».
          </p>
        </div>
      </div>

      <div ref={historyRef} {...events} className={styles.history}>
        <div className={styles.historyItem}>
          <p className={styles.year}>1942</p>
          <div className={styles.historyBox}>
            <p className={styles.historyTitle}>НАЧАЛО Истории </p>
            <p className={styles.historyDescription}>
              История завода началась во время Великой Отечественной войны.
              Началось производство продукции для фронта: пленка была нужна для
              аэрофотосъемки в разведывательных целях и для составления карт
              местности.
            </p>
          </div>
        </div>
        <div className={styles.historyItem}>
          <p className={classNames(styles.year, styles.yearReverse)}>1952</p>
          <div
            className={classNames(styles.historyBox, styles.historyBoxReverse)}
          >
            <p className={styles.historyTitle}>фотографическая бумага</p>
            <p className={styles.historyDescription}>
              С 1952 года фабрика стала выпускать только фотографическую бумагу,
              полностью отойдя от военного производства.
            </p>
          </div>
        </div>
        <div className={styles.historyItem}>
          <p className={styles.year}>1976</p>
          <div className={styles.historyBox}>
            <p className={styles.historyTitle}>Красноярский завод</p>
            <p className={styles.historyDescription}>
              Фабрику переименовали в Красноярский завод светочувствительных
              материалов «Квант».
            </p>
          </div>
        </div>
        <div className={styles.historyItem}>
          <p className={classNames(styles.year, styles.yearReverse)}>1994</p>
          <div
            className={classNames(styles.historyBox, styles.historyBoxReverse)}
          >
            <p className={styles.historyTitle}>торговый комплекс квант</p>
            <p className={styles.historyDescription}>
              Предприятие просуществовало до мая 1994 года. Позже часть площадей
              завода занял торговый комплекс «Квант».
            </p>
          </div>
        </div>
        <div className={styles.historyItem}>
          <p className={styles.year}>2019</p>
          <div className={styles.historyBox}>
            <p className={styles.historyTitle}>лофт-проект Квадрат</p>
            <p className={styles.historyDescription}>
              Фабрику переименовали в Красноярский завод светочувствительных
              материалов «Квант».
            </p>
          </div>
        </div>
        <div className={styles.historyItem}>
          <p className={classNames(styles.year, styles.yearReverse)}>2022</p>
          <div
            className={classNames(styles.historyBox, styles.historyBoxReverse)}
          >
            <p className={styles.historyTitle}>новый этап</p>
            <p className={styles.historyDescription}>
              «Квадрат» получил поддержку Правительства края, и его перезапуском
              занялся Сибирский институт развития креативных индустрий.
            </p>
          </div>
        </div>
        <div className={styles.historyItem}>
          <p className={styles.year}>2023</p>
          <div className={styles.historyBox}>
            <p className={styles.historyTitle}>обновилась политика</p>
            <p className={styles.historyDescription}>
              Для резидентов кластера модернизированная политика предлагает
              льготную арендную ставку, размер которой теперь зависит от стадии
              развития проекта.
            </p>
          </div>
        </div>
      </div>
      <div className={styles.audioWrapper} id='audio' ref={audioRef}>
        <AudioPlayer
          audioPlayerRef={audioPlayerRef}
          key={currentSongIndex}
          currentSong={currentSong}
          songCount={audio.length}
          songIndex={currentSongIndex}
          onNext={() => setCurrentSongIndex((i) => i + 1)}
          onPrev={() => setCurrentSongIndex((i) => i - 1)}
        />
        <button
          className={classNames(styles.listOfAudio, {
            [styles.listOfAudioActive]: play,
          })}
          onClick={() => {
            setPlay(!play);
          }}
        >
          Аудиогид
        </button>
        <ul
          style={play ? { height: list.current?.scrollHeight + 'px' } : {}}
          ref={list}
          className={styles.songs}
        >
          {audio.map((audio) => (
            <li
              onClick={() => {
                setCurrentSongIndex(audio.order_index - 1);
                audioRef.current?.scrollIntoView({ behavior: 'smooth' });
              }}
              key={audio.id}
            >
              {audio.title}
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
export default ClusterRoadmap;
