import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './ArchivePage.module.scss';
import PageTemplate from '../../../templates/PageTemplate';
import Container from '../../../templates/Container';

import { getArchives } from '../../../../redux/requests/getArchives.js';

import useMobileDetect from 'use-mobile-detect-hook';
import ClampLines from 'react-clamp-lines';

const ArchivePage = () => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  let [exhibitions, setExhibitions] = useState([]);

  useEffect(() => {
    getArchives().then((res) => {
      setExhibitions(res.results);
    });
  }, []);



  return (
    <PageTemplate>
      <Container>
        <div className={isMobile ? `${styles.archive} ${styles.archive__mobile}` : styles.archive}>

          <h1 className={isMobile ? `${styles.title} ${styles.title__mobile}` : styles.title}>Архив</h1>

          <div className={isMobile ? `${styles.list} ${styles.list__mobile}` : styles.list}>
            {exhibitions.map((item) => (

              (item.is_archived && <Link to={'/archive/' + item.id} key={item.id}
                className={isMobile ? `${styles.exhibition} ${styles.exhibition__mobile}` : styles.exhibition}
              >
                <div className={isMobile ? `${styles.image} ${styles.image__mobile}` : styles.image}>

                  {isMobile ?
                    <span>Перейти</span> :
                    <span>Подробнее</span>
                  }

                  <img src={item.image} alt="" />
                </div>
                <h2>{item.title}</h2>

                <ClampLines
                  text={item.description}
                  id={item.id}
                  ellipsis="..."
                  moreText="Читать далее"
                  lessText="Collapse"
                  innerElement="p"
                  lines={isMobile ? 6 : 4}
                  />

              </Link>)
            ))}
          </div>


        </div>
      </Container>
    </PageTemplate>
  )
}

export default ArchivePage