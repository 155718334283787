import { CLUSTER_API } from '../API/BASE_URL';

export const sendRoomReserve = async (roomData, setDisabled) => {
  setDisabled(true);
  const response = await fetch(CLUSTER_API + 'request/booking/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(roomData),
  });
  const data = await response.json();
  return data;
};
