import ClusterAddress from './ClusterAddress/ClusterAddress';
import ClusterDocumentation from './ClusterDocumentation/ClusterDocumentation';
import ClusterInfo from './ClusterInfo/ClusterInfo';
import ClusterRoadmap from './ClusterRoadmap/ClusterRoadmap';
import ClusterRezidents from './ClusterRezidents/ClusterRezidents';
import ClusterTitle from './ClusterTitle/ClusterTitle';
import Rezidents from '../Rezidents/Rezidents';
import ClusterFooter from './ClusterFooter/ClusterFooter';
import ClusterTabs from './ClusterTabs/ClusterTabs';
import ClusterArenda from './ClusterArenda/ClusterArenda';
import PromoPaths from '../../events/PromoPaths/PromoPaths';
import ClusterPromo from './ClusterPromo/ClusterPromo';

const AboutCluster = () => {
  return (
    <>
      <ClusterTitle />
      <ClusterRoadmap />
      <ClusterRezidents />
      <Rezidents />
      <ClusterTabs />
      <ClusterArenda />
      <ClusterPromo />
      <ClusterFooter />
      {/* <PromoPaths /> */}
      {/* <ClusterInfo /> */}
      {/* <ClusterAddress /> */}
      {/* <ClusterDocumentation /> */}
    </>
  );
};
export default AboutCluster;
