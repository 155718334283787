import classNames from 'classnames';
import image1 from '../../../../../assets/img/cluster/imageCluster1.png';
import image2 from '../../../../../assets/img/cluster/imageCluster2.png';
import image3 from '../../../../../assets/img/cluster/imageCluster3.png';
import { ReactComponent as Question } from '../../../../../assets/img/cluster/question.svg';
import { ReactComponent as ClusterRezidentsSVG } from '../../../../../assets/img/cluster/clusterRezidents.svg';

import styles from './ClusterRezidents.module.scss';
import { Link } from 'react-router-dom';

const ClusterRezidents = () => {
  return (
    <section className={styles.clusterRezidents}>
      <div className={styles.header}>
        <ul className={styles.list}>
          <li>
            <a href='#arenda'>Провести мероприятие</a>
          </li>
          <li>
            <Link to='/showroom'>Посетить шоурум «Индустрия»</Link>
          </li>
          <li>
            <a href='#residents'>Разместить мастерскую или офис</a>
          </li>
        </ul>
        <p className={styles.title}>
          уникальные проекты <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; на стыке
          индустрий!
        </p>
      </div>
      <p className={styles.headerTitle}>
        / Преимущества для тех, кто стал частью кластера
      </p>
      <div className={styles.images}>
        <div className={styles.image}>
          <img src={image1} />
          <div className={styles.imageText}>
            <p className={styles.imageTitle}>Удобное </p>
            <p className={styles.imageTitle}>расположение</p>
            <p className={styles.imageSubTitle}>в самом центре города </p>
          </div>
        </div>
        <div className={styles.image}>
          <img src={image2} />
          <div className={styles.imageText}>
            <p className={styles.imageTitle}>Сообщество</p>
            <p className={styles.imageTitle}>единомышленников</p>
            <p className={styles.imageSubTitle}>творческие коллаборации</p>
          </div>
        </div>
        <div className={styles.image}>
          <img src={image3} />
          <div className={styles.imageText}>
            <p className={styles.imageTitle}>Свобода</p>
            <p className={styles.imageTitle}>творчества</p>
            <p className={styles.imageSubTitle}>
              стильный дизайн, зоны нетворкинга, круглосуточный доступ
            </p>
          </div>
        </div>
      </div>
      <div id='residents' className={styles.howWrapper}>
        <div className={styles.how}>
          <span>Как стать</span>
          <span className={styles.bold}>резидентом</span>
          <Question className={styles.questionSvg} />
        </div>
        <ClusterRezidentsSVG className={styles.rezidentSvg} />
      </div>
    </section>
  );
};
export default ClusterRezidents;
