import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import HeaderButtonGroup from '../HeaderButtonGroup/HeaderButtonGroup';
import Logo from '../Logo/Logo';

import Burger from '../Burger/Burger';

import styles from './DesktopHeader.module.scss';
import { useSelector } from 'react-redux';
// import { logDOM } from '@testing-library/react';

const DesktopHeader = (props) => {
  const location = useLocation();

  // Проверка наличия архивных выставок для отображения пункта меню "Архив выставок"
  const dataGallery = useSelector((state) => state.exhibitions.exhibitions);
  const [containDataGallery, setContainDataGallery] = useState(0);
  useEffect(() => {
    setContainDataGallery(dataGallery.find((item) => item.is_archived));
  }, [dataGallery]);

  const [modalActive, setModalActive] = useState(false);
  useEffect(() => {
    !modalActive
      ? (document.body.style.overflow = 'auto')
      : (document.body.style.overflow = 'hidden');
  }, [modalActive]);

  // плавный скролл до партнеров и до верха страницы
  const linkLogo = useRef();
  const scrollToTop = function () {
    const root = document.getElementById('root');
    root.scrollTo(0, 0);
  };
  useEffect(() => {
    if (!linkLogo || !linkLogo.current) return;
    linkLogo.current.addEventListener('click', scrollToTop);
    return () => {
      linkLogo.current?.removeEventListener('scroll', scrollToTop);
    };
  }, [location.pathname]);

  return (
    <>
      <nav
        className={
          styles.header +
          ' ' +
          (props.background ? styles.header__black : styles.header__white) +
          ' ' +
          (props.scroll ? styles.header__scroll : '')
        }
        onClick={() => (modalActive ? setModalActive(false) : null)}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <NavLink
            to='/'
            style={{ marginRight: 50 }}
            className={({ isActive }) => (isActive ? styles.pressed : '')}
            ref={linkLogo}
          >
            <Logo className={styles.mainlogo} />
          </NavLink>

          <div
            className={
              styles.navLinks +
              ' ' +
              (props.background
                ? styles.navLinks__black
                : styles.navLinks__white)
            }
          >
            <NavLink
              to='/cluster#cluster'
              className={({ isActive }) => (isActive ? styles.pressed : '')}
            >
              О&nbsp;Кластере
            </NavLink>
            <NavLink
              to='/gallery'
              className={({ isActive }) => (isActive ? styles.pressed : '')}
            >
              Галерея
            </NavLink>

            <NavLink
              to='/showroom'
              className={({ isActive }) => (isActive ? styles.pressed : '')}
            >
              Шоурум
            </NavLink>

            <NavLink
              to='/#partners'
              className={location.hash === '#partners' ? styles.pressed : ''}
            >
              Партнеры
            </NavLink>
          </div>
        </div>

        <HeaderButtonGroup
          header={props.header}
          order={props.order}
          orderLink={props.orderLink}
          orderDict={props.orderDict}
        />

        <Burger
          modalActive={modalActive}
          setModalActive={setModalActive}
          order={props.order}
        />
      </nav>

      {/* Модальное окно */}
      <div
        className={
          styles.panel +
          ' ' +
          (modalActive ? '' : styles.panel__hide) +
          ' ' +
          (props.background ? styles.panel__dark : '')
        }
        onClick={() =>
          modalActive ? setModalActive(false) : setModalActive(true)
        }
      >
        <div
          className={styles.overlay}
          onClick={() => setModalActive(false)}
        ></div>

        {/* Контентная часть */}
        <div
          className={
            styles.panel_content +
            ' ' +
            (modalActive ? '' : styles.panel_content__hide) +
            ' ' +
            (props.background
              ? styles.panel_content__dark
              : styles.panel_content__white)
          }
          onClick={() => setModalActive(false)}
        >
          <NavLink
            to='/painters'
            className={({ isActive }) => (isActive ? styles.pressed : '')}
          >
            Художники
          </NavLink>

          {containDataGallery && (
            <NavLink
              to='/archive'
              className={({ isActive }) => (isActive ? styles.pressed : '')}
            >
              Архив выставок
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
};

export default DesktopHeader;
