import React, { useEffect, useState } from 'react';
import PalletSwitch from '../../../../elements/PalletSwitch/PalletSwitch';
import ButtonsBlot from '../../../../elements/ButtonsBlot/ButtonsBlot';

import loader from '../../../../../assets/img/loader.gif';

import styles from '../MainPage.module.scss';
import DescriptionSlider from './Slider/DescriptionSlider';

import { ReactComponent as ArrowRight } from '../../../../../assets/img/arrowRight.svg';
import Container from '../../../../templates/Container';
import AudioPlayer from '../../../../elements/Audio/AudioPlayer/AudioPlayer';
import { songs } from '../../../../../assets/songs/songs';
import { useRef } from 'react';

const MainPageContent = ({
  data,
  sliderContent,
  activeExhibition,
  setActiveExhibition,
  exhibitionsCount,
  marginTop,
  next,
}) => {
  const { image, title, sub_title, description, is_background_black } = data;
  const [isLoadImage, setLoadImage] = useState(false);
  // useEffect(() => {
  //   setLoadImage(true);
  // }, [image]);
  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const currentSong = songs[currentSongIndex];
  const audioPlayerRef = useRef();

  return (
    <div
      className={
        styles.main_page_wrapper +
        ' ' +
        (is_background_black ? styles.black_bg : '')
      }
      style={{
        marginTop: marginTop ? 50 : '',
      }}
    >
      <Container>
        <div className={styles.main_page_container}>
          <div className={styles.image_wrapper}>
            <img
              className={styles.mainImage}
              src={image}
              alt={title}
              style={{ filter: isLoadImage ? 'blur(20px)' : 'blur(0px)' }}
              onLoad={() => setLoadImage(false)}
              onLoadStart={() => setLoadImage(true)}
            />
            {/* {isLoadImage && <img className={styles.loader} src={loader} />} */}
          </div>
          <div className={styles.desc_wrapper}>
            <h1>{title}</h1>
            <h2>
              {data.id == 11
                ? `${sub_title.split(';')[0]}
${sub_title.split(';')[1]}`
                : sub_title}
            </h2>
            {/* <div> */}
            {/* <ButtonsBlot
                activeExhibition={activeExhibition}
                setActiveExhibition={setActiveExhibition}
                exhibitionsCount={exhibitionsCount}
              /> */}
            {/* <PalletSwitch
      className={styles.main_page_container}
      style={marginTop ? { marginTop: "50px" } : { marginTop: "" }}
    >
      <div className={styles.image_wrapper}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.desc_wrapper}>
        <h1>{title}</h1>
        <h2>{sub_title}</h2>
        <div><ButtonsBlot
            activeExhibition={activeExhibition}
            setActiveExhibition={setActiveExhibition}
            exhibitionsCount={exhibitionsCount}
          />
          <PalletSwitch
            title={title}
            activeExhibition={activeExhibition}
            setActiveExhibition={setActiveExhibition}
            isActivateButtons={exhibitionsCount > 1}
          /> */}
            {/* </div> */}
            <div className={styles.slider_wrapper}>
              <DescriptionSlider
                // description={description}
                sliderContent={description}
              />
            </div>
            {data.id == 11 && (
              <div>
                <AudioPlayer
                  audioPlayerRef={audioPlayerRef}
                  key={currentSongIndex}
                  currentSong={currentSong}
                  songCount={songs.length}
                  songIndex={currentSongIndex}
                  onNext={() => setCurrentSongIndex((i) => i + 1)}
                  onPrev={() => setCurrentSongIndex((i) => i - 1)}
                />
              </div>
            )}
          </div>
          <div className={styles.next} onClick={() => next(data.id)}>
            <ArrowRight />
          </div>
        </div>
      </Container>
    </div>
  );
};

export default MainPageContent;
