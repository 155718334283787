import styles from './ClusterArenda.module.scss';

import arenda8 from '../../../../../assets/img/cluster/arenda/arenda8.jpg';
import { ReactComponent as Doc } from '../../../../../assets/img/cluster/arenda/doc.svg';
import { ReactComponent as LISVG } from '../../../../../assets/img/cluster/rezidents/programms/licurrent.svg';
import { ReactComponent as LISVG2 } from '../../../../../assets/img/cluster/rezidents/programms/li.svg';
import { ReactComponent as CalendarSVG } from '../../../../../assets/img/cluster/arenda/calendar.svg';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import Calendar from 'react-calendar';
import './Calendar.css';
import useWindowWidth from '../../../../../hooks/useWindowWidth';
import { getDocsRoom } from '../../../../../redux/requests/getDocsRoom';
import { useEffect } from 'react';
import { getRoom } from '../../../../../redux/requests/getRoom';
import { sendRoomReserve } from '../../../../../redux/requests/sendRoomReserve';
import ReactInputMask from 'react-input-mask';
import { getBookingCalendar } from '../../../../../redux/requests/getBookingCalendar';
import { getEquipment } from '../../../../../redux/requests/getEquipment';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CustomSlider.scss';
import Table from './Table/Table';

const ClusterArenda = () => {
  const width = useWindowWidth();
  const [openThings, setOpenThings] = useState(false);
  const [openThings2, setOpenThings2] = useState(false);
  const [things, setThings] = useState(1);
  const [disabled, setDisabled] = useState(false);
  const [success, setSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [openName, setOpenName] = useState('');
  const [calendar, setCalendar] = useState(0);
  const [calendarTime, setCalendarTime] = useState(false);
  const [docs, setDocs] = useState([]);
  const [rooms, setRooms] = useState([]);
  const [booking, setBooking] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const list = useRef();
  const list2 = useRef();

  const [fio, setFio] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [INN, setINN] = useState('');
  const [text, setText] = useState('');
  const [room, setRoom] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [addBookingDays, setAddBookingDays] = useState([]);

  const settings = {
    dotsClass: 'dots',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    getDocsRoom().then((data) => setDocs(data));
    getRoom().then((data) => setRooms(data));
    getEquipment().then((data) => setEquipment(data));
  }, []);

  useEffect(() => {
    if (room != '') getBookingCalendar(room).then((data) => setBooking(data));
  }, [room]);

  const sendData = async () => {
    const res = await sendRoomReserve(
      {
        full_name: fio,
        phone: phone,
        email: email,
        inn: INN,
        days: addBookingDays,
        // end_date: formatDateToYYYYMMDD(endDate),
        requirements: text,
        room: room,
      },
      setDisabled
    );
    setDisabled(false);

    if (res?.days) {
      setSuccess(true);
      resetAll();
    }
  };

  const resetAll = () => {
    setOpen(false);
    setFio('');
    setPhone('');
    setEmail('');
    setINN('');
    setText('');
    setRoom('');
    setStartDate('');
    setEndDate('');
    setAddBookingDays([]);
  };

  return (
    <section id='arenda' className={styles.clusterArenda}>
      {success && (
        <div className={styles.modal}>
          <div className={styles.content}>
            <p className={styles.successTitle}>
              Ваша заявка принята.
              <br /> Мы скоро свяжемся с вами!
            </p>
            <button
              onClick={() => setSuccess(false)}
              className={styles.closeButton}
            >
              закрыть х
            </button>
          </div>
        </div>
      )}

      {open && (
        <div className={styles.modal}>
          <div className={styles.content}>
            <p className={styles.titleBron}>Заявка на бронирование помещения</p>
            <p className={styles.name}>
              Бронирование <br />
              {openName}
            </p>
            <div className={styles.input}>
              <label htmlFor='fio'>ФИО заявителя*</label>
              <input
                value={fio}
                onChange={(e) => setFio(e.target.value)}
                id='fio'
              />
            </div>
            <div className={styles.input}>
              <label htmlFor='email'>Почта*</label>
              <input
                type='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id='email'
              />
            </div>
            <div className={styles.input}>
              <label htmlFor='phone'>Телефон*</label>
              <ReactInputMask
                id='phone'
                type='tel'
                mask='+7\ 999 999-99-99'
                maskchar='_'
                required={true}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
              {/* <input
                type='phone'
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                id='phone'
              /> */}
            </div>
            <div className={styles.input}>
              <label htmlFor='inn'>ИНН заявителя*</label>
              <input
                value={INN}
                onChange={(e) => setINN(e.target.value)}
                id='inn'
              />
            </div>
            <div className={styles.input}>
              <label htmlFor='startDate'>
                Укажите дату и время мероприятия*
              </label>
              <textarea
                rows='7'
                value={formatBookingDate(addBookingDays)}
                id='startDate'
              />
              <CalendarSVG onClick={() => setCalendar(1)} />
            </div>
            {/* <div className={styles.input}>
              <label htmlFor='endDate'>
                Укажите дату и время окончания мероприятия*
              </label>
              <input
                value={endDate && formatDateToYYYYMMDD(endDate)}
                id='endDate'
              />
              <CalendarSVG onClick={() => setCalendar(2)} />
            </div> */}
            <div className={styles.inputText}>
              <label htmlFor='text'>
                Укажите требования райдера* (вводится текст)
              </label>
              <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                rows='7'
                id='text'
              />
            </div>
            <button
              disabled={disabled}
              onClick={sendData}
              className={styles.bronButton}
            >
              Забронировать
            </button>
            <button onClick={resetAll} className={styles.closeButton}>
              ОК
            </button>
          </div>
        </div>
      )}
      {!!calendar && (
        <div className={styles.modal}>
          <div className={styles.contentCalendar}>
            <Calendar
              // tileDisabled={({ activeStartDate, date, view }) => {
              //   const tmp = [];
              //   for (let i = 0; i < booking.length; i++) {
              //     if (booking[i].room == room) {
              //       tmp.push(
              //         booking[i].from_date.slice(-5, -3) ==
              //           activeStartDate.getMonth() + 1 &&
              //           booking[i].from_date.slice(-2)
              //       );
              //       tmp.push(
              //         booking[i].to_date.slice(-5, -3) ==
              //           activeStartDate.getMonth() + 1 &&
              //           booking[i].to_date.slice(-2)
              //       );
              //     }
              //   }
              //   const without = tmp.map((str) => parseInt(str, 10));
              //   for (let i = 0; i < without.length; i += 2) {
              //     if (date.getDate() == without[i]) {
              //       return date.getDate();
              //     } else if (!without[i] && !!without[i + 1]) {
              //       return date.getDate() <= without[i + 1];
              //     } else if (!without[i + 1] && !!without[i]) {
              //       return date.getDate() > without[i];
              //     } else {
              //       for (let j = without[i]; j < without[i + 1] + 1; j++) {
              //         if (date.getDate() == j) return date.getDate();
              //       }
              //     }
              //   }
              // }}
              minDetail='month'
              showNeighboringMonth={false}
              locale='ru-RU'
              next2Label={null}
              prev2Label={null}
              className={styles.calendar}
              onChange={
                calendar == 1
                  ? (e) => {
                      setCalendarTime(true);
                      setStartDate(e);
                    }
                  : (e) => {
                      setCalendarTime(true);
                      setEndDate(e);
                    }
              }
              value={calendar == 1 ? startDate : endDate}
            />
            {/* <p className={styles.calendarTime}>Укажите время бронирования</p>
            <div className={styles.timeWrapper}>
              <span>с</span>
              <input
                type='time'
                placeholder='10:00'
                className={styles.inputCalendar}
              />
              <span>до</span>
              <input
                type='time'
                placeholder='17:00'
                className={styles.inputCalendar}
              />
            </div> */}
            <div className={styles.calendarWrapper}>
              <div className={styles.blockWrapper}>
                <div className={styles.block3}></div>
                <p className={styles.blockText}>Сегодняшний день</p>
              </div>
              <div className={styles.blockWrapper}>
                <div className={styles.block}></div>
                <p className={styles.blockText}>Помещение свободно</p>
              </div>
              <div className={styles.blockWrapper}>
                <div className={styles.block2}></div>
                <p className={styles.blockText}>
                  Помещение забронировано на весь день
                </p>
              </div>
              <p className={styles.linkWithUs}>
                *Свяжитесь с нами возможен сдвиг в расписании
              </p>
            </div>
            <button
              onClick={() => setCalendar(false)}
              className={styles.calendarButton}
            >
              ОК
            </button>
          </div>
        </div>
      )}
      {calendarTime && (
        <div onClick={() => setCalendarTime(false)} className={styles.modal}>
          <div
            onClick={(e) => e.stopPropagation()}
            className={styles.contentCalendar2}
          >
            <Table
              booking={booking}
              startDate={startDate}
              addBookingDays={addBookingDays}
              setAddBookingDays={setAddBookingDays}
            />
            <p className={styles.titleCalendar}>Укажите время бронирования </p>
            <div className={styles.calendarWrapper2}>
              <div className={styles.blockWrapper}>
                <div className={styles.block}></div>
                <p className={styles.blockText}>Свободно</p>
              </div>
              <div className={styles.blockWrapper}>
                <div className={styles.block2}></div>
                <p className={styles.blockText}>Забронировано</p>
              </div>
              <div className={styles.blockWrapper}>
                <div className={styles.block3}></div>
                <p className={styles.blockText}>Выбранные даты</p>
              </div>
            </div>
            <p className={styles.linkWithUs}>
              *Свяжитесь с нами возможен сдвиг в расписании
            </p>
            <button
              onClick={() => setCalendarTime(false)}
              className={styles.calendarButton}
            >
              ОК
            </button>
          </div>
        </div>
      )}
      <p className={styles.title}>АРЕНДА ПОМЕЩЕНИЙ</p>
      <div className={styles.items}>
        {rooms?.map((room) => (
          <div key={room.id} className={styles.item}>
            <div className={styles.first}>
              <Slider {...settings}>
                {room.gallery.map((image, id) => (
                  <img key={id} className={styles.slider} src={image.image} />
                ))}
              </Slider>
            </div>
            <div className={styles.second}>
              <p className={styles.secondTitle}>{room.title}</p>
              <p
                className={styles.list}
                dangerouslySetInnerHTML={{
                  __html: room.description,
                }}
              ></p>
              <div className={styles.tags}>
                {room.options.map((option, id) => (
                  <p key={id} className={styles.tag}>
                    {option}
                  </p>
                ))}
              </div>
            </div>
            <div className={styles.third}>
              <script data-b24-form='click/17/1oqx96' data-skip-moving='true'>
                {(function (w, d, u) {
                  var s = d.createElement('script');
                  s.async = true;
                  s.src = u + '?' + ((Date.now() / 180000) | 0);
                  var h = d.getElementsByTagName('script')[0];
                  h.parentNode.insertBefore(s, h);
                })(
                  window,
                  document,
                  'https://cdn-ru.bitrix24.ru/b24021132/crm/form/loader_17.js'
                )}
              </script>
              <button className={styles.firstButton}>
                Получить консультацию
              </button>
              <button
                onClick={async () => {
                  setOpen(true);
                  setOpenName(room.title);
                  setRoom(room.id);
                }}
                className={styles.secondButton}
              >
                Забронировать
              </button>
              <div className={styles.priceWrapper}>
                <div className={styles.price}>
                  {room.coasts.map((coast, id) => (
                    <p key={id}>
                      <b>{coast.period}</b>– {coast.cost} руб/час
                    </p>
                  ))}
                </div>
                <p className={styles.times}>*количество часов в неделю</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className={styles.inputButtons}>
        <div
          onClick={() => {
            setThings(1);
            setOpenThings(!openThings);
          }}
          className={classNames(styles.greenButton, {
            [styles.greenButtonActive]: things == 1 && openThings,
          })}
        >
          <p>Мобильные посадочные места </p>
          <LISVG2 />
        </div>
        {/* <div
          onClick={() => {
            setThings(2);
            setOpenThings2(!openThings2);
          }}
          className={classNames(styles.grayButton, {
            [styles.grayButtonActive]: things == 2 && openThings,
          })}
        >
          <p>Мобильное техническое оборудование </p> <LISVG2 />
        </div> */}
      </div>
      <div
        style={openThings ? { height: list.current?.scrollHeight + 'px' } : {}}
        ref={list}
        className={styles.tovars}
      >
        {equipment
          .find((item) => item.id == 1)
          ?.items?.map((item, id) => (
            <div key={id} className={styles.tovar}>
              <img src={item.cover} />
              <p className={styles.tovarName}>{item.title}</p>
              <p className={styles.tovarCount}>Количество – {item.count} шт.</p>
            </div>
          ))}
      </div>
      {/* <div
        style={
          openThings2 ? { height: list2.current?.scrollHeight + 'px' } : {}
        }
        ref={list2}
        className={styles.tovars}
      >
        {equipment
          .find((item) => item.id == 2)
          ?.items?.map((item, id) => (
            <div key={id} className={styles.tovar}>
              <img src={item.cover} />
              <p className={styles.tovarName}>{item.title}</p>
              <p className={styles.tovarCount}>Количество – {item.count} шт.</p>
            </div>
          ))}
      </div> */}

      <div className={styles.card}>
        <div className={styles.cardText}>
          <p className={styles.cardTitle}>
            Поможем подобрать зал для вашего мероприятия
          </p>
          <p className={styles.cardDescription}>
            Если вы не знаете, какое пространство выбрать, мы поможем вам
            подобрать лучший вариант
          </p>
          <script data-b24-form='click/17/1oqx96' data-skip-moving='true'>
            {(function (w, d, u) {
              var s = d.createElement('script');
              s.async = true;
              s.src = u + '?' + ((Date.now() / 180000) | 0);
              var h = d.getElementsByTagName('script')[0];
              h.parentNode.insertBefore(s, h);
            })(
              window,
              document,
              'https://cdn-ru.bitrix24.ru/b24021132/crm/form/loader_17.js'
            )}
          </script>
          <button className={styles.cardButton}>Подать заявку</button>
        </div>
        {width > 800 && <img className={styles.arendaImage} src={arenda8} />}
      </div>

      <p className={styles.title}>документация</p>

      <div className={styles.docs}>
        {docs.map((doc) => (
          <div key={doc.id} className={styles.doc}>
            <Doc />
            <p className={styles.docName}>{doc.title}</p>
            <a target='_blank' href={doc.document} className={styles.download}>
              Скачать
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ClusterArenda;

export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + day;
};

const formatBookingDate = (date) => {
  const formattedDate = date.map((item) => {
    return {
      date: item.date,
      hours: item.hours.sort((a, b) => parseInt(a) - parseInt(b)),
    };
  });

  formattedDate.sort((a, b) => new Date(a.date) - new Date(b.date));

  const formattedStrings = formattedDate.map((item) => {
    const timeString = item.hours.join(', ');
    return `${item.date} - ${timeString}`;
  });

  const result = formattedStrings.join('\n');

  return result;
};
