import { BASE_URL_V2 } from '../API/BASE_URL';

export const getCouponsUsage = async (token) => {
  const response = await fetch(
    BASE_URL_V2 + 'partners/managers-coupons-usage/',
    {
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        Authorization: token ? 'Token ' + token : '',
      },
    }
  );

  const data = await response.json();

  return data;
};
