import React from "react";
import styles from "../../MainPage.module.scss";
import "./Slider.scss";

const DescriptionSlider = ({ sliderContent }) => {
  let settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    // adaptiveHeight: true,
  };
  let description = sliderContent?.split("\n");

  return (
    <div className={styles.description_text}>
      {description?.map((item, index) => (
        <span key={index}>{item}</span>
      ))}
    </div>

    // <Slider {...settings}>
    //   {description.map((item, index) => (
    //     <div className={styles.slide} key={index}>
    //       <p>{item}</p>
    //     </div>
    //   ))}
    // </Slider>
  );
};

export default DescriptionSlider;
