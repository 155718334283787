import React, { useState, useEffect } from 'react';
import styles from './PromoSection.module.scss';
import MasterSection from '../MasterSection/MasterSection';
import EventSection from '../EventSection/EventSection';
import { getEvents } from '../../../../redux/requests/getEvents';

const PromoSection = () => {
  const [logos, setLogos] = useState([]);
  const [blackscreen, setBlackscreen] = useState(true);

  let [promoTitle, setPromoTitle] = useState('');
  let [promoDates, setPromoDates] = useState('');
  let [promoCity, setPromoCity] = useState('');
  let [promoPlace, setPlace] = useState('');
  let [backgroundImage, setbackgroundImage] = useState('');
  let [registrationLink, setRegistrationLink] = useState('');

  useEffect(() => {
    getEvents().then((res) => {
      let eventsData = res.results[0];
      setBlackscreen(false);
      if (res.results.length < 1) {
        // Если с бека не пришёл ни одно мероприятие, то не устанавливаем значение
        return;
      }
      setPromoDates(eventsData.dates);
      setPromoCity(eventsData.main_location);
      setPlace(eventsData.sub_location);
      setPromoTitle(eventsData.title);
      setLogos(eventsData.partners);
      setbackgroundImage(eventsData.background);
      setRegistrationLink(eventsData.registration_link);
    });
  }, []);

  return (
    <>
      {promoTitle ?
        <EventSection
          promoTitle={promoTitle}
          promoDates={promoDates}
          promoCity={promoCity}
          promoPlace={promoPlace}
          backgroundImage={backgroundImage}
          registrationLink={registrationLink}
          logos={logos}
        />
        : <MasterSection
          blackscreen={blackscreen}
        /> }
      {blackscreen ? <div className={styles.blackscreen} id='blackscreen'></div> : null}
    </>
  )
}

export default PromoSection