import React, { useEffect, useState } from 'react';
import ReactInputMask from 'react-input-mask';

import styles from './OrderModal.module.scss';
import { useSelector } from 'react-redux';
import { setPayments } from '../../../redux/requests/setPayments';

import useMobileDetect from 'use-mobile-detect-hook';

const OrderModal = ({
  onClose,
  url,
  product_ids,
  localStorageVariableName,
  orderDict,
}) => {
  const detectMobile = useMobileDetect();
  const isMobile = detectMobile.isMobile();

  const [paymentsData, setPaymentsData] = useState({
    email: '',
    fio: '',
    phone: '',
    product_ids: '',
  });
  const [isEmailValid, setEmailValid] = useState(true);
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [isNameValid, setNameValid] = useState(true);

  const confirmOrder = () => {
    let validEmail = true;
    let validPhone = true;
    let validName = true;
    const EMAIL_REGEXP =
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu;
    if (!EMAIL_REGEXP.test(paymentsData.email)) {
      setEmailValid(false);
      validEmail = false;
    }
    const PHONE_REGEXP = /\+7\(\d{3}\)\d{3}-\d{2}-\d{2}/g;
    if (!PHONE_REGEXP.test(paymentsData.phone)) {
      setPhoneValid(false);
      validPhone = false;
    }
    let FIO = paymentsData.fio;
    while (FIO[FIO.length - 1] === ' ') {
      FIO = FIO.slice(0, -1);
    }

    const isThreeWords = FIO.split(' ').length === 3;
    if (!isThreeWords) {
      setNameValid(false);
      validName = false;
    }
    if (!validName || !validEmail || !validPhone) {
      return;
    } else if (
      paymentsData.email &&
      paymentsData.fio &&
      paymentsData.phone &&
      paymentsData.product_ids
    ) {
      setPayments(paymentsData, url, localStorageVariableName);
    }
  };

  const closeModal = () => onClose(false);

  useEffect(() => {
    if (!orderDict) return;

    let orderArray = [];
    for (let key in orderDict) {
      let item = orderDict[key];
      orderArray.push(`${item.id}: ${item.count}`);
    }
    let orderToString = orderArray.join(', ');
    setPaymentsData({ ...paymentsData, product_ids: orderToString });
  }, [orderDict]);

  return (
    <div
      className={isMobile ? styles.mobile_modal_screen : styles.modal_screen}
    >
      <div className={styles.modal_wrapper}>
        <h3>Оформление заказа</h3>
        {!isEmailValid && (
          <p style={{ color: 'red', marginBottom: 10 }}>
            Поле E-mail заполнено некорректно
          </p>
        )}
        {!isPhoneValid && (
          <p style={{ color: 'red', marginBottom: 10 }}>
            Укажите телефон в формате +7(123)456-78-90
          </p>
        )}
        {!isNameValid && (
          <p style={{ color: 'red', marginBottom: 10, textAlign: 'center' }}>
            Поле ФИО должно содержать три слова <br /> (двойную фамилию следует
            указывать через тире)
          </p>
        )}
        <input
          value={paymentsData.email}
          type='email'
          placeholder='E-mail'
          onChange={(event) => {
            if (!isEmailValid) {
              setEmailValid(true);
            }
            setPaymentsData({ ...paymentsData, email: event.target.value });
          }}
        />
        <input
          value={paymentsData.fio}
          type='text'
          placeholder='ФИО'
          onChange={(event) => {
            if (!isNameValid) {
              setNameValid(true);
            }
            setPaymentsData({ ...paymentsData, fio: event.target.value });
          }}
        />     
        <ReactInputMask         
          type='tel'
          mask='+7\(999)999-99-99'
          placeholder='Номер телефона'
          maskChar='_'
          value={paymentsData.phone}
          onChange={(event) => {
            if (!isPhoneValid) {
              setPhoneValid(true);
            }
            setPaymentsData({ ...paymentsData, phone: event.target.value });
          }}
        />
        <div className={styles.button_container}>
          <button onClick={closeModal}>Назад</button>
          <button onClick={confirmOrder}>Далее</button>
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
