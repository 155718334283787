import React from 'react';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import ReactTelegramLogin from 'react-telegram-login';

const TgAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.search.includes('?')) {
      const pathToApp =
        // 'exp://192.168.0.3:19000/--/screens/auth-tg?' + location.search.split('?')[1];
        'industry-app://screens/auth-tg?' + location.search.split('?')[1];
      window.location.href = pathToApp;
      navigate('/');
    }
  }, [location]);

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <ReactTelegramLogin
        botName='IndustryLoginBot'
        dataAuthUrl='https://place.industry.art/tg-auth'
      />
    </div>
  );
};

export default TgAuth;
