import { CLUSTER_API } from '../API/BASE_URL';

export const getEventsCluster = async () => {
  const response = await fetch(CLUSTER_API + 'events/', {
    method: 'GET',
  });

  const data = await response.json();

  return data;
};
