import { CLUSTER_API } from '../API/BASE_URL';

export const getOrgs = async () => {
  const response = await fetch(CLUSTER_API + 'events/organizers/', {
    method: 'GET',
  });

  const data = await response.json();
  return data;
};
